import React from "react";

import "./moving-text.m.css";

export function MovingText({ children }) {
  const text = children;
  const poormansduration = Math.ceil(text.length / 3);

  return (
    <div styleName="marquee" title={children}>
      <div styleName="animate marquee-text" style={{ animationDuration: poormansduration + "s" }}>
        {children}
      </div>
      <div styleName="animate marquee-text" style={{ animationDuration: poormansduration + "s" }}>
        {children}
      </div>
    </div>
  );
}

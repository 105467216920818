/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { SocialIcons } from "../../molecules/social-icons";
import get from "lodash/get";
import PT from "prop-types";
import "./footer.m.css";

function FooterBase(props) {
  const placeholders = props.menu.filter(
    item => item["item-type"] === "placeholder"
  );

  const pushToDataLayer = (event, title) => {
    const obj = {
      event: event,
      usertype: props.member ? "logged in" : "logged out",
      subscribertype: props.fetchSubscription
        ? props.fetchSubscription.isActive
          ? "subscriber"
          : "nonsubscriber"
        : "nonsubscriber",
      userID: props.member ? props.member.id : "null",
      subscriptionID: props.fetchSubscription
        ? props.fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    if (event === "bottom_navigation_click") {
      obj.bottom_navigation_cta = title;
    } else if (event === "nl_bottom_navigation") {
      if (title === "Subscribe") {
        obj.subscription_category = "self_subscription";
      }
      if (title === "Newsletters") {
        obj.subscription_category = "newsletter_subscription";
      }
      if (title === "Join NL Sena") {
        obj.subscription_category = "contribution_nl_sena";
      }
    }

    window.dataLayer.push(obj);
  };

  return (
    <footer styleName="footer">
      {/* <div styleName="test"></div> */}

      <div className="container">
        <div styleName="container">
          <ul styleName="menu-items">
            {placeholders.map(item => {
              return (
                <li key={item.id} styleName="menu-items-list">
                  <h3>{item.title}</h3>
                  <ul styleName="menu-items-list">
                    {item.children.map(child => (
                      <li
                        key={child.id}
                        onClick={() => {
                          if (
                            child.title === "Subscribe" ||
                            child.title === "Newsletters"
                          ) {
                            return pushToDataLayer(
                              "nl_bottom_navigation",
                              child.title
                            );
                          } else {
                            return pushToDataLayer(
                              "bottom_navigation_click",
                              child.title
                            );
                          }
                        }}
                      >
                        <a href={child.completeUrl}>{child.title}</a>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
          {!props.domainSlug ? (
            <div styleName="social-links">
              <h3>Follow</h3>
              <SocialIcons
                socialLinks={props.socialLinks}
                showPodcast
                showTelegram
                showLinkedin
                showSpotify
                showCastBox
                showRss
                showWhatsapp

              />
            </div>
          ) : (
            <div styleName="social-links">
              <p>हमसे जुड़ें</p>
              <SocialIcons
                socialLinks={props.socialLinks}
                // showDiscord
                showNewsletter
                showRSS
              />
            </div>
          )}
          <div styleName="last-div">
            {/* <div styleName="about">
              {!props.domainSlug ? (
                <h5>With your support, we can do more ground-reports</h5>
              ) : (
                <h5>
                  आपका सहयोग हमें ज्यादा से ज्यादा ग्राउंड रिपोर्ट करने की ताकत
                  देता है
                </h5>
              )}
              {!props.domainSlug ? (
                <a
                  styleName="nl-btn"
                  href="/sena?ref=website-footer"
                  onClick={() =>
                    pushToDataLayer("nl_bottom_navigation", "Join NL Sena")
                  }
                >
                  Join NL Sena
                </a>
              ) : (
                <a
                  styleName="nl-btn"
                  href={`/subscription?ref=footer-hindi`}
                  onClick={() =>
                    pushToDataLayer("nl_bottom_navigation", "Join NL Sena")
                  }
                >
                  सब्सक्राइब करें
                </a>
              )}
            </div> */}
            <div styleName="download-app-div">
              <h3>Download the Newslaundry app</h3>
              <div styleName="download-cta-div">
                <a
                  href="https://apps.apple.com/in/app/newslaundry/id1609227019?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.assettype.com/newslaundry/2022-05/13b3de80-f642-4a15-be70-874855d17ba4/512x512.png"
                    alt="app store button"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.newslaundry.android.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.assettype.com/newslaundry/2022-05/c4d901fe-bf33-4d3a-b720-58c2182a8e9c/512x512.png"
                    alt="google play store button"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div styleName="copyright">
          <p styleName="copyright-text">
            © Newslaundry Media Private Limited. All Rights Reserved.
          </p>
          {/* <a href="https://www.quintype.com">
            <p styleName="copyright-text">Powered by Quintype</p>
          </a> */}
        </div>
      </div>
    </footer>
  );
}

FooterBase.propTypes = {
  menu: PT.arrayOf(
    PT.shape({
      completeUrl: PT.string,
      title: PT.string
    })
  ),
  socialLinks: PT.object,
  domainSlug: PT.string
};

function mapStateToProps(state) {
  const menu =
    get(state, ["qt", "data", "navigationMenu", "footerLinks"], []) ||
    get(state, ["qt", "data", "navigationMenu", "default"], []);
  return {
    menu: menu,
    socialLinks: get(state, ["qt", "config", "social-links"], []),
    domainSlug: get(state, ["qt", "domainSlug"], null),
    frontEndUrl: get(state, ["qt", "config", "publisher", "frontend_url"], ""),
    member: get(state, ["member"], null),
    subscription: get(state, ["fetchSubscription"], {})
  };
}

export const Footer = connect(mapStateToProps)(FooterBase);

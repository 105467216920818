/* eslint-disable max-len */
import { useEffect, useLayoutEffect, useState } from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { parseUrl } from "query-string";
import copy from "copy-to-clipboard";
import IntegrationData from "../../../../config/integrations";
import * as api from "./api";
import {
  USER_SUBSCRIPTION_RECEIVED,
  USER_SUBSCRIPTION_REQUESTED,
  USER_LOGOUT_SUBSCRIPTION_CLEAR,
  USER_COUNTRY_REQUEST,
  USER_COUNTRY_FAILURE,
  USER_COUNTRY_SUCCESS
} from "./actions";
import TimeZoneDate from "./timezone.json";

// import axios from "axios";

export const setUserSubscriptionData = async dispatch => {
  try {
    const integrationsConfig = IntegrationData();

    const token = await api.getIntegrationToken();
    const accessTypeKey = integrationsConfig.isAccesstypeStaging
      ? integrationsConfig.stagingAccessTypeKey
      : integrationsConfig.accessTypeKey;

    const url = `https://www.accesstype.com/api/access/v1/members/me/subscriptions?key=${accessTypeKey}&accesstype_jwt=${token}`;

    dispatch({ type: USER_SUBSCRIPTION_REQUESTED });

    // eslint-disable-next-line no-undef
    fetch(url, {
      method: "get"
    })
      .then(res => res.json())
      .then(response => {
        // dispatch({
        //   type: USER_SUBSCRIPTION_RECEIVED,
        //   items: response.subscriptions
        // });
        if (
          response &&
          response.subscriptions &&
          response.subscriptions.length
        ) {
          if (response.subscriptions.find(sub => sub.active || sub.pending)) {
            dispatch({
              type: USER_SUBSCRIPTION_RECEIVED,
              isActive: true,
              isExpired: false,
              isRecurring: false,
              items: response.subscriptions
            });
          } else {
            dispatch({
              type: USER_SUBSCRIPTION_RECEIVED,
              isActive: false,
              isRecurring: false,
              isExpired: true,
              items: response.subscriptions
            });
          }
          if (
            response.subscriptions.find(
              sub =>
                sub.active === true &&
                ["recurring"].includes(sub.plan_occurrence.toLowerCase())
            )
          ) {
            dispatch({
              type: USER_SUBSCRIPTION_RECEIVED,
              isRecurring: true,
              isActive: true,
              isExpired: false,
              items: response.subscriptions
            });
          }
        } else {
          dispatch({
            type: USER_SUBSCRIPTION_RECEIVED,
            isActive: false,
            isExpired: false,
            isRecurring: null,
            items: []
          });
        }
      });
  } catch (error) {
    dispatch({
      type: USER_SUBSCRIPTION_RECEIVED,
      isActive: false,
      isExpired: false,
      isRecurring: null,
      items: []
    });
    console.error(error);
  }
};

export const clearUserSubscriptionData = async dispatch => {
  dispatch({ type: USER_LOGOUT_SUBSCRIPTION_CLEAR });
};

export function useUserSubscription() {
  const userHasSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "items"], null)
  );
  return userHasSubscription;
}

export function useCopyToClipboard(resetInterval = null) {
  const [isCopied, setCopied] = useState(false);

  function handleCopy(text) {
    if (typeof text === "string" || typeof text === "number") {
      copy(text.toString());
      setCopied(true);
    } else {
      setCopied(false);
      console.error(
        `Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`
      );
    }
  }

  return [isCopied, handleCopy];
}

export function getAuthUrl() {
  const authHost = useSelector(state =>
    get(state, ["qt", "config", "publisher", "publisher", "auth_host"], null)
  );
  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );
  const params = parseUrl(currentPath);
  const callbackUrl = get(
    params,
    ["query", "callback-url"],
    global.location && global.location.origin
  );
  const redirectUrl = get(
    params,
    ["query", "redirect-url"],
    global && global.location && global.location.href
  );
  const url = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
  return url;
}

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

export const useScrollLock = () => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = originalStyle);
  }, []);
};

export const getUserCountry = async dispatch => {
  dispatch({ type: USER_COUNTRY_REQUEST });

  try {
    const x = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const userTimeZone = TimeZoneDate.timezones.find(
      tz => tz.timezone.toLowerCase() === x.toLowerCase()
    );

    const userCountry = TimeZoneDate.countries.find(c =>
      userTimeZone.country_codes.includes(c.country_code)
    );

    dispatch({
      type: USER_COUNTRY_SUCCESS,
      country_code: userCountry.country_code,
      country_name: userCountry.country_name,
      country_code_alias: userCountry.country_code_alias,
      user_timezone: x
    });
  } catch (error) {
    dispatch({
      type: USER_COUNTRY_FAILURE,
      country_code: "",
      country_name: "",
      country_code_alias: "",
      user_timezone: ""
    });
    console.error(error);
  }
};

export const isMobileScreen = () => {
  const [width, setWidth] = useState(0);

  const handleWindowSizeChange = e => {
    const w = e.target;

    setWidth(w.innerWidth);
  };

  useEffect(() => {
    setWidth(window.innerWidth);

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      return window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ArrowUp, PauseButton } from "./icons";
import { Loader } from "./Loader";

import "./mobile-bottom-player.m.css";
import { MobilePlayerFullScreen } from "./mobile-player-full-screen";
import { MovingText } from "./moving-text";
import { PlayerContext } from "./player-context";

export function MobileBottomPlayer({
  track,
  status,
  handlePlay,
  handlePause,
  handleClose,
  minsLeft
}) {
  const playerState = useContext(PlayerContext);

  const { currentlyPlaying } = playerState;

  const toggleFullScreen = () => {
    playerState.setShowPlayerFullScreen(!playerState.showPlayerFullScreen);
  };
  const { member, fetchSubscription } = useSelector(state => state);

  const pushToDataLayer = (event, timeStamp) => {
    const obj = {
      event: event,
      podcast_name: currentlyPlaying.title,
      podcast_creator: currentlyPlaying.showTitle,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    if (timeStamp) {
      obj.podcast_completion = Math.ceil(timeStamp);
      obj.max_podcast_duration = Math.ceil(Number(currentlyPlaying.duration));
    }

    window.dataLayer.push(obj);
  };

  const renderPlayButton = () => {
    if (status === "playing") {
      return (
        <button
          aria-label="Pause"
          onClick={() => {
            pushToDataLayer("podcast_pause", playerState.currentTime);
            handlePause();
          }}
          className="pressable"
          styleName="player-control"
        >
          <PauseButton width="24" />
        </button>
      );
    } else if (status === "loading") {
      return <Loader />;
    }

    return (
      <button
        aria-label="Play"
        onClick={() => {
          pushToDataLayer("podcast_play", playerState.currentTime);
          handlePlay();
        }}
        className="pressable"
        styleName="player-control"
      >
        <PlayButton />
      </button>
    );
  };

  return (
    <>
      <div styleName="bottom_player_mobile">
        <div style={{ lineHeight: 0, padding: "0 1rem" }}>
          <button
            aria-label="Open full screen player"
            onClick={toggleFullScreen}
            className="pressable"
          >
            <ArrowUp width="20" />
          </button>
        </div>
        <button
          onClick={toggleFullScreen}
          styleName="bottom_player_mobile_meta"
          className="pressable"
        >
          <div styleName="bottom_player_mobile_meta--title">
            <MovingText>{track.title}</MovingText>
          </div>
          <div styleName="bottom_player_mobile_meta--time">
            {minsLeft} {minsLeft > 1 ? " mins" : " min"} left
          </div>
        </button>
        <div styleName="bottom_player_mobile_controls">
          {renderPlayButton()}
          <button
            aria-label="Stop player"
            styleName="player-control"
            className="pressable"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <MobilePlayerFullScreen
        toggleFullScreen={toggleFullScreen}
        showFullScreen={playerState.showPlayerFullScreen}
      />
    </>
  );
}

function PlayButton() {
  return (
    <svg
      width="16"
      height="28"
      viewBox="0 0 38 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.6465 21.8341C37.3016 22.2384 37.2733 23.2002 36.5956 23.5654L1.52481 42.4609C0.847121 42.826 0.0282923 42.3206 0.0509273 41.5511L1.22229 1.73121C1.24492 0.961738 2.09204 0.505322 2.7471 0.909659L36.6465 21.8341Z"
        fill="#E3E2E2"
      />
    </svg>
  );
}

function CloseIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5922 30.2561L23.2922 17.9157L35.6203 5.73451C36.1266 5.22853 36.1266 4.40396 35.6203 3.89797L32.1141 0.374805C31.8703 0.131182 31.5422 0 31.1953 0C30.8484 0 30.5203 0.140552 30.2766 0.374805L18.0047 12.5185L5.71406 0.384175C5.47031 0.140552 5.14219 0.00937069 4.79531 0.00937069C4.44844 0.00937069 4.12031 0.149922 3.87656 0.384175L0.379688 3.90734C-0.126562 4.41333 -0.126562 5.2379 0.379688 5.74388L12.7078 17.925L0.417188 30.2561C0.173438 30.4997 0.0328128 30.8277 0.0328128 31.1744C0.0328128 31.5211 0.164063 31.849 0.417188 32.0927L3.92344 35.6158C4.17656 35.8688 4.50469 36 4.84219 36C5.17031 36 5.50781 35.8782 5.76094 35.6158L18.0047 23.3222L30.2578 35.6065C30.5109 35.8594 30.8391 35.9906 31.1766 35.9906C31.5047 35.9906 31.8422 35.8688 32.0953 35.6065L35.6016 32.0833C35.8453 31.8397 35.9859 31.5117 35.9859 31.165C35.9766 30.8277 35.8359 30.4997 35.5922 30.2561Z"
        fill="#E3E2E2"
      />
    </svg>
  );
}

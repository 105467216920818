/* eslint-disable react/prop-types */
import React from "react";

import * as TabsPrimitive from "@radix-ui/react-tabs";

const Tabs = React.forwardRef(
  ({ children, variant, defaultValue, ...props }, forwardedRef) => {
    const allClassNames = `tabs-root ${props.className || ""} ${
      props.styleName || ""
    }`;

    return (
      <TabsPrimitive.Root
        ref={forwardedRef}
        defaultValue={defaultValue}
        data-variant={variant}
        {...props}
        className={allClassNames}
      >
        {children}
      </TabsPrimitive.Root>
    );
  }
);
Tabs.displayName = TabsPrimitive.Root.displayName;

const TabsList = React.forwardRef(({ children, ...props }, forwardedRef) => {
  const allClassNames = `tabs-list ${props.className || ""} ${
    props.styleName || ""
  }`;

  return (
    <TabsPrimitive.List ref={forwardedRef} {...props} className={allClassNames}>
      {children}
    </TabsPrimitive.List>
  );
});
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef(
  ({ children, value, ...props }, forwardedRef) => {
    const allClassNames = `tabs-trigger ${props.className || ""} ${
      props.styleName || ""
    }`;

    return (
      <TabsPrimitive.Trigger
        ref={forwardedRef}
        value={value}
        {...props}
        className={allClassNames}
      >
        {children}
      </TabsPrimitive.Trigger>
    );
  }
);
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef(
  ({ children, value, ...props }, forwardedRef) => {
    const allClassNames = `tabs-content ${props.className || ""} ${
      props.styleName || ""
    }`;

    return (
      <TabsPrimitive.Content
        ref={forwardedRef}
        value={value}
        {...props}
        className={allClassNames}
      >
        {children}
      </TabsPrimitive.Content>
    );
  }
);
TabsContent.displayName = TabsPrimitive.Content.displayName;

const TabsCompoundComponent = Object.assign({}, Tabs, {
  List: TabsList,
  Trigger: TabsTrigger,
  Content: TabsContent
});

export { TabsCompoundComponent as Tabs };

import React from "react";
import PT from "prop-types";

export function InstagramIcon({ width = 14, height = 14, color = "#fff" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14">
      <g fill={color} fillRule="nonzero">
        <path d="M10.08 0H3.842A3.847 3.847 0 0 0 0 3.842v6.239a3.846 3.846 0 0 0 3.842 3.842h6.238a3.847 3.847 0 0 0 3.843-3.842V3.842A3.847 3.847 0 0 0 10.08 0zm2.608 10.08a2.61 2.61 0 0 1-2.607 2.607H3.842a2.61 2.61 0 0 1-2.607-2.606V3.842a2.61 2.61 0 0 1 2.607-2.607h6.238a2.61 2.61 0 0 1 2.607 2.607v6.239z" />
        <path d="M6.961 3.374a3.592 3.592 0 0 0-3.587 3.588 3.591 3.591 0 0 0 3.587 3.587 3.591 3.591 0 0 0 3.588-3.587A3.592 3.592 0 0 0 6.96 3.374zm0 5.94A2.355 2.355 0 0 1 4.61 6.96 2.355 2.355 0 0 1 6.961 4.61a2.355 2.355 0 0 1 2.353 2.352A2.355 2.355 0 0 1 6.96 9.314zM10.7 2.327a.91.91 0 0 0-.907.905c0 .239.098.472.266.641a.913.913 0 0 0 .64.265.915.915 0 0 0 .641-.265.91.91 0 0 0 0-1.281.91.91 0 0 0-.64-.265z" />
      </g>
    </svg>
  );
}

InstagramIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};

import { useEffect } from "react";

let playerRef;

export const setGlobalPlayerRef = ref => {
  playerRef = ref;
};

export const getGlobalPlayerRef = () => {
  return playerRef;
};

export function timestampify(text) {
  const newString = text.replace(
    /(([0-9]|[0-9][0-9]):[0-9][0-9]:[0-9][0-9]|[0-9][0-9]:[0-9][0-9])/gm,
    '<button data-time="$&" class="timestamp-link pressable">$&</button>'
  );
  return newString;
}

// hh:mm:ss -> seconds
// mm:ss -> seconds
// ss -> seconds
export function hmsToSecondsOnly(str) {
  const p = str.split(":");

  let s = 0;
  let m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }

  return s;
}

export const useEscapeKey = (callback, dependencies) => {
  useEffect(() => {
    if (!window || !window.document || !callback) {
      return;
    }

    const ESCAPE_KEY = 27;

    if (!Array.isArray(dependencies)) {
      dependencies = [];
      console.warn("Dependencies must be an array!");
    }

    const onKeyPress = event => event.keyCode === ESCAPE_KEY && callback(event);
    window.document.addEventListener("keydown", onKeyPress);
    return () => {
      window.document.removeEventListener("keydown", onKeyPress);
    };
  }, dependencies);
};

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

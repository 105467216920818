import React from "react";
import PT from "prop-types";
import "./offcanvas-menu.m.css";
import { CloseIcon } from "../../atoms/close-icon";

// OffcanvasMenu will only enabled in mobile devices. It will just act as a wrapper for larger screens
export class OffcanvasMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerHeight: "auto"
    };
  }

  componentDidUpdate() {
    if (this.props.showOffcanvasMenu) {
      document.body.classList.add("nl-sidemenu--open");
    } else {
      document.body.classList.remove("nl-sidemenu--open");
    }
  }

  componentDidMount() {
    if (window.navigator.userAgent.includes("Mobile")) {
      if (window.navigator.userAgent.toLowerCase().includes("iphone")) {
        this.setState({ hamburgerHeight: "80vh" });
      } else {
        this.setState({ hamburgerHeight: "calc(100vh - 80px)" });
      }
    }
  }

  render() {
    return (
      <div styleName="offcanvas-menu">
        {/* <div
          styleName={`overlay ${
            this.props.showOffcanvasMenu ? "overlay-active" : ""
          }`}
          onClick={this.props.closeMenu}
        ></div> */}
        <div
          // style={{ height: this.state.hamburgerHeight }}
          styleName={`sidebar ${this.props.showOffcanvasMenu ? "is-open" : ""}`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

OffcanvasMenu.propTypes = {
  showOffcanvasMenu: PT.bool,
  closeMenu: PT.func,
  children: PT.object
};

/* eslint-disable react/prop-types */
import React from "react";
import get from "lodash/get";
import { connect, useSelector } from "react-redux";
// import * as button from "../../atoms/button";
// import * as Loader from "../../podcast-player/Loader";
import { Link } from "@quintype/components";
// import { Button } from "../../../ui/components/button";

import "./subscription-status-btn.m.css";

const SubscriptionStatusBtnWrapper = ({ member = {}, config }) => {
  const isLoading = useSelector(state =>
    get(state, ["fetchSubscription", "isLoading"], null)
  );
  const isActive = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );
  const isExpired = useSelector(state =>
    get(state, ["fetchSubscription", "isExpired"], null)
  );
  const fetchSubscription = useSelector(state =>
    get(state, ["fetchSubscription"], {})
  );

  const getButtonText = () => {
    if (member) {
      if (isLoading) {
        return "Loading...";
      }
      if (isActive) {
        return "Gift";
      }
      if (isExpired) {
        return "Renew";
      }
      return "Subscribe";
    }
    return "Subscribe";
  };

  const pushToDataLayer = event => {
    let title = "";

    if (isActive) {
      title = "gift_subscription";
    } else {
      title = "self_subscription";
    }

    const obj = {
      event: event,
      subscription_category: title,
      usertype: member ? "logged in" : "logged out",
      subscribertype: isActive ? "subscriber" : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  return (
    <Link
      href={`/${isActive ? "gift" : "subscription"}?ref=nav`}
      styleName="redirect-link"
      onMouseDown={() => pushToDataLayer("nl_top_navigation")}
    >
      {getButtonText()}
    </Link>
    // <Link
    //   href={`/${isActive ? "gift" : "subscription"}?ref=nav`}
    //   styleName="redirect-link"
    // >
    //   <Button
    //     variant="accent"
    //     isLoading={isLoading}
    //     className="component-padding-x-lg"
    //     styleName="font-bold"
    //     onMouseDown={() => pushToDataLayer("nl_top_navigation")}
    //   >
    //     {getButtonText()}
    //   </Button>
    // </Link>
  );

  // return (
  //   <button.Button
  //     href={`/${isActive ? "gift" : "subscription"}?ref=nav`}
  //     onMouseDown={() => pushToDataLayer("nl_top_navigation")}
  //   >
  //     {" "}
  //     {getButtonText()}{" "}
  //   </button.Button>
  // );
};

const mapStateToProps = state => {
  return {
    member: get(state, ["member"], null),
    userSubscription: get(state, ["isSubscribedUser", "userSubscription"], []),
    userHasSubscription: get(
      state,
      ["isSubscribedUser", "userHasSubscription"],
      null
    ),
    config: get(state, ["qt", "config"], {})
  };
};

export const SubscriptionStatusBtn = connect(
  mapStateToProps,
  null
)(SubscriptionStatusBtnWrapper);

import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { Modal } from "../modal";
import PropTypes from "prop-types";
import assetify from "@quintype/framework/assetify";
import { SocialShare } from "@quintype/components";
import { parseUrl } from "query-string";
import Axios from "axios";

import { SenaSuccessForm } from "./sena-success-form";
import { GameChangerForm } from "./gc-form";
import ShareSuccessMessage from "./share-success-message";
import { getIntegrationToken } from "../../helper/api";

import "./show-success-msg.m.css";
import subscriptionSuccessImage from "../../../../assets/images/nl-images/nl-sena-page.png";

class ShowSuccessMsgBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkSuccessMsg: false,
      showSenaForm: false,
      showGameChangerForm: false,
      showIDayForm: false,
      isStudentSubscription: false
    };
  }

  componentDidMount() {
    const checkSuccessMsg =
      this.props.currentPath.indexOf("?subscription-status=success") > -1;
    const showSenaForm = this.props.currentPath.indexOf("sena=true") > -1;
    const showGameChangerForm =
      this.props.currentPath.indexOf("gamechanger=true") > -1;
    const showIDayForm = this.props.currentPath.indexOf("iday=true") > -1;
    const isStudentSubscription =
      this.props.currentPath.indexOf("student-sub=true") > -1;

    this.setState(
      {
        checkSuccessMsg,
        showSenaForm,
        showGameChangerForm,
        showIDayForm,
        isStudentSubscription
      },
      async () => {
        if (this.state.isStudentSubscription) {
          const token = await getIntegrationToken();

          const params = parseUrl(this.props.currentPath);
          const authCode = params.query.code;

          if (!authCode) {
            return;
          }

          try {
            const res = await Axios.post(
              "/create-student-subscription",
              { studentId: authCode, integrationToken: token },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`
                }
              }
            );

            // console.log({ res: res.data });

            console.log("done");
          } catch (error) {
            console.error(error);
          }
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentPath !== prevProps.currentPath) {
      const checkSuccessMsg =
        this.props.currentPath.indexOf("?subscription-status=success") > -1;
      const showSenaForm = this.props.currentPath.indexOf("sena=true") > -1;
      const showGameChangerForm =
        this.props.currentPath.indexOf("gamechanger=true") > -1;
      const showIDayForm = this.props.currentPath.indexOf("iday=true") > -1;
      const isStudentSubscription =
        this.props.currentPath.indexOf("student-sub=true") > -1;

      this.setState(
        {
          checkSuccessMsg,
          showSenaForm,
          showGameChangerForm,
          showIDayForm,
          isStudentSubscription
        },
        async () => {
          if (this.state.isStudentSubscription) {
            const token = await getIntegrationToken();

            const params = parseUrl(this.props.currentPath);
            const authCode = params.query.code;

            if (!authCode) {
              return;
            }

            try {
              const res = await Axios.post(
                "/create-student-subscription",
                { studentId: authCode, integrationToken: token },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                  }
                }
              );

              // console.log({ res: res.data });

              console.log("done");
            } catch (error) {
              console.error(error);
            }
          }
        }
      );
    }
  }

  OnClick = () => {
    this.props.navigateTo(window.location.pathname);
  };

  RenderForms = (showSenaForm, showGameChangerForm, showIDayForm) => {
    if (showSenaForm) {
      return (
        <div styleName="sena-form">
          <SenaSuccessForm handleClose={this.OnClick} />
        </div>
      );
    } else if (showGameChangerForm) {
      return (
        <div styleName="sena-form">
          <GameChangerForm handleClose={this.OnClick} />
        </div>
      );
    } else if (showIDayForm) {
      return (
        <div styleName="sena-form">
          <GameChangerForm handleClose={this.OnClick} mode={3} />
        </div>
      );
    } else {
      return (
        <div>
          <div styleName="image-wrapper">
            <img
              styleName="success-image"
              src={assetify(subscriptionSuccessImage)}
              alt="subscription success image"
            ></img>
          </div>
          <div styleName="share-wrapper">
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              Spread the message:{" "}
              <div style={{ color: "var(--primary-color)" }}>
                {" "}
                #PayToKeepNewsFree
              </div>
            </div>
            <SocialShare
              fullUrl={"https://newslaundry.com/subscription"}
              title={"I #PayToKeepNewsFree to support independent media\n\n"}
              services={["facebook", "twitter", "whatsapp", "linkedin"]}
              story={null}
              template={ShareSuccessMessage}
            />
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        {this.state.checkSuccessMsg && (
          <Modal onBackdropClick={this.OnClick}>
            <div styleName="modal-content modal-wrapper">
              You’re a star! Thank you so much for paying to keep news free and
              supporting our journalism. <br />
              {this.RenderForms(
                this.state.showSenaForm,
                this.state.showGameChangerForm,
                this.state.showIDayForm
              )}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

ShowSuccessMsgBase.propTypes = {
  currentPath: PropTypes.object,
  navigateTo: PropTypes.func
};

function mapStateToProps(state) {
  return {
    menu: get(state, ["qt", "data", "navigationMenu"], []),
    currentPath: get(state, ["qt", "currentPath"], "")
  };
}

function mapDispatch(dispatch) {
  return {
    navigateTo: url => global.app.navigateToPage(dispatch, url)
  };
}

export const ShowSuccessMsg = connect(
  mapStateToProps,
  mapDispatch
)(ShowSuccessMsgBase);

import React from "react";
import PT from "prop-types";

export function GplusIcon({ width = 13, height = 13, color = "#fff" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 13">
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(0.000000, -4.000000)"
      >
        <g id="Group">
          <g id="googleplus" transform="translate(0.000000, 4.000000)" fill={color} fillRule="nonzero">
            <path
              d="M6.3521875,5.22140244 L6.3521875,7.77859756 C6.3521875,7.77859756 8.79265625,7.77526829 9.78640625,7.77526829 C9.24828125,9.43292683 8.4115625,10.3356341 6.3521875,10.3356341 C4.268125,10.3356341 2.6415625,8.61836585 2.6415625,6.5 C2.6415625,4.38163415 4.268125,2.66436585 6.3521875,2.66436585 C7.4540625,2.66436585 8.16578125,3.0580122 8.8184375,3.60670732 C9.3409375,3.07560976 9.29734375,2.99982927 10.6267188,1.72376829 C9.49828125,0.679646341 7.99828125,0.0432804878 6.3521875,0.0432804878 C2.84390625,0.0432804878 0,2.93403659 0,6.5 C0,10.0659634 2.84390625,12.9567195 6.3521875,12.9567195 C11.5960938,12.9567195 12.8776563,8.31556098 12.4528125,5.22140244 L6.3521875,5.22140244 Z M17.79875,5.34934146 L17.79875,3.1117561 L16.2264063,3.1117561 L16.2264063,5.34918293 L13.9621875,5.34918293 L13.9621875,6.94739024 L16.2264063,6.94739024 L16.2264063,9.24886585 L17.79875,9.24886585 L17.79875,6.94754878 L20,6.94754878 L20,5.34934146 L17.79875,5.34934146 Z"
              id="Google_Plus_1_"
            ></path>
          </g>
          <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
        </g>
      </g>
    </svg>
  );
}

GplusIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};

import React from "react";
import PropTypes from "prop-types";
import "./social-media-shoutout.m.css";

export function SocialMediaShoutout({ onChange, values, name }) {
  return (
    <>
      <div styleName="form-group text">
        <div>Will you be okay with a social media shout-out?</div>
        <div>
          <label>
            <input type="radio" name={name} value="yes" checked={values[name] === "yes"} onChange={onChange} /> Yes
          </label>
          &nbsp;
          <label>
            <input type="radio" name={name} value="no" checked={values[name] === "no"} onChange={onChange} /> No
          </label>
        </div>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", opacity: values[name] === "no" ? 0.5 : 1 }}>
        <div styleName="form-group" style={{ marginRight: "1rem" }}>
          <label htmlFor="twitterHandle" styleName="text">
            Twitter handle
          </label>
          <input
            required
            disabled={values[name] === "no"}
            styleName="input-text"
            value={values.twitterHandle}
            id="twitterHandle"
            placeholder="@username"
            type="text"
            name="twitterHandle"
            onChange={onChange}
          ></input>
        </div>
        <div styleName="form-group">
          <label htmlFor="instagramHandle" styleName="text">
            Instagram handle
          </label>
          <input
            styleName="input-text"
            disabled={values[name] === "no"}
            required
            value={values.instagramHandle}
            id="instagramHandle"
            type="text"
            placeholder="@username"
            name="instagramHandle"
            onChange={onChange}
          ></input>
        </div>
      </div>
    </>
  );
}

SocialMediaShoutout.propTypes = {
  values: PropTypes.obj,
  onChange: PropTypes.func,
  name: PropTypes.string
};

/* eslint-disable react/prop-types */
import React from "react";

import * as VisuallyHiddenPrimitive from "@radix-ui/react-visually-hidden";

const VisuallyHidden = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    const allClassNames = `${props.className || ""} ${props.styleName || ""}`;

    return (
      <VisuallyHiddenPrimitive.Root
        ref={forwardedRef}
        {...props}
        className={allClassNames}
      >
        {children}
      </VisuallyHiddenPrimitive.Root>
    );
  }
);
VisuallyHidden.displayName = VisuallyHiddenPrimitive.Root.displayName;

export { VisuallyHidden };

import React from "react";
import PT from "prop-types";

export function LinkedinIcon({ width = 17, height = 18, color = "#fff" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 18">
      <path
        fill={color}
        fillRule="evenodd"
        d="M17 18h-3.644v-6.498c0-1.632-.527-2.746-1.846-2.746-1.006 0-1.605.75-1.87 1.477-.095.26-.12.62-.12.984V18H5.875s.05-11.005 0-12.145H9.52v1.721l-.024.04.024-.04c.484-.826 1.349-2.007 3.284-2.007C15.202 5.57 17 7.305 17 11.036V18zM2.062 0C.816 0 0 .906 0 2.098c0 1.166.792 2.1 2.014 2.1h.024c1.272 0 2.062-.934 2.062-2.1C4.076.906 3.31 0 2.062 0zM.216 18H3.86V5.855H.216V18z"
      />
    </svg>
  );
}

LinkedinIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};

import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { parseUrl } from "query-string";
import WithSSO from "../../with-sso";
// import UserIcon from "../../atoms/icons/user-icon";
import { UserData } from "../user-data";
import { WithMember, Link } from "@quintype/components";
import { IS_OPEN_LOGIN_FORM } from "../../helper/actions";
import { FaRegCircleUser } from "react-icons/fa6";

import "./user-profile-icon.m.css";

class UserBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callbackUrl: null,
      redirectUrl: null
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    const params = parseUrl(this.props.currentPath);
    this.setState({
      callbackUrl: get(
        params,
        ["query", "callback-url"],
        global.location && global.location.origin
      ),
      redirectUrl: get(
        params,
        ["query", "redirect-url"],
        global && global.location && global.location.href
      )
    });
  }

  componentDidUpdate(prevProps) {
    const { isLoginOpen } = this.props;
    if (prevProps.currentPath !== this.props.currentPath) {
      this.setState({
        redirectUrl: `${this.props.currentHostUrl}${this.props.currentPath}`
      });
    }
    if (isLoginOpen !== prevProps.isLoginOpen && isLoginOpen) {
      this.props.manageLoginForm(true);
      this.setState({ loginFormOpen: true });
    }
    if (isLoginOpen !== prevProps.isLoginOpen && !isLoginOpen) {
      this.props.manageLoginForm(false);
      this.setState({ loginFormOpen: false });
    }
  }

  toggleModal() {
    this.setState(prevState => {
      this.props.manageLoginForm(!prevState.loginFormOpen);
      return {
        loginFormOpen: !prevState.loginFormOpen
      };
    });
  }

  render() {
    return (
      <WithMember>
        {({ member, logout, checkForMemberUpdated, isLoading }) => {
          return (
            !isLoading && (
              <React.Fragment>
                {!member
                  ? window.localStorage.setItem("one-tap-display", true)
                  : window.localStorage.setItem("one-tap-display", false)}
                {!member ? (
                  <WithSSO
                    ssoHost={this.props.authHost}
                    signInPath="/user-login"
                    redirectUrl={this.state.redirectUrl}
                    callbackUrl={this.state.callbackUrl}
                  >
                    {({ signInHref }) => (
                      <Link
                        href={signInHref}
                        styleName="user-profile-icon"
                        aria-label="Click to go to Login page"
                      >
                        <FaRegCircleUser aria-hidden="true" />
                        {/* <button
                          className="pressable"
                          aria-label="Login button"
                          styleName="user-profile-icon"
                        >
                          <UserIcon />
                        </button> */}
                      </Link>
                    )}
                  </WithSSO>
                ) : (
                  <div>
                    <UserData
                      member={member}
                      logout={logout}
                      onBackdropClick={() =>
                        this.setState({ loginFormOpen: false })
                      }
                    />
                  </div>
                )}
              </React.Fragment>
            )
          );
        }}
      </WithMember>
    );
  }
}
UserBase.propTypes = {
  isLoginOpen: PropTypes.bool,
  manageLoginForm: PropTypes.func,
  member: PropTypes.object,
  authHost: PropTypes.string,
  currentPath: PropTypes.string,
  currentHostUrl: PropTypes.string,
  accessTypeEnvironment: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function (payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  }
});

const mapStateToProps = state => {
  return {
    isLoginOpen: get(state, ["isLoginOpen"], false),
    member: get(state, ["member"], null),
    currentPath: get(state, ["qt", "currentPath"], ""),
    currentHostUrl: get(state, ["qt", "currentHostUrl"], ""),
    authHost: get(
      state,
      ["qt", "config", "publisher", "publisher", "auth_host"],
      null
    ),
    domainSlug: get(state, ["qt", "config", "domainSlug"], ""),
    subDomainUrl: get(
      state,
      ["qt", "config", "publisher", "subdomain_url"],
      ""
    ),
    frontEndUrl: get(state, ["qt", "config", "publisher", "frontend_url"], ""),
    accessTypeEnvironment: get(state, [
      "qt",
      "config",
      "publisher",
      "publisher",
      "accessTypeEnvironment"
    ])
  };
};

export const User = connect(mapStateToProps, mapDispatchToProps)(UserBase);

import React from "react";
import assetify from "@quintype/framework/assetify";
import PropTypes from "prop-types";
import { SUBSCRIPTION_PAGE_URL } from "../../../constants";
import subscriptionImage from "../../../../assets/images/nl-images/subscription-CTA.png";
import { Button } from "../../atoms/button";
import get from "lodash/get";
import { connect } from "react-redux";
import { SUBSCRIPTION_CTA } from "../../helper/actions";
import "./subscription-cta.m.css";

function redirectHandler(url, subscriptionCTAHandle, navigateTo) {
  subscriptionCTAHandle({ clickedCTA: true, isOpen: false });
  navigateTo(url);
}

const SubscriptionCTACopyBase = ({ userSubscription, subscriptionCTAHandle, navigateTo }) => {
  return userSubscription.isOpen ? (
    <div styleName="wrapper">
      <div styleName="image-wrapper">
        <img styleName="image" src={assetify(subscriptionImage)} alt="newslaundry logo" />
      </div>
      <div styleName="content-wrapper">
        <h4 styleName="headline">Pay to keep news free  </h4>
        <div styleName="content">
        Oops! This is available for subscribers only. Pay to keep news free and get access to paywall podcasts.
        </div>
        <div styleName="button">
          <Button
            className="nl-btn-large"
            onClick={() => redirectHandler(SUBSCRIPTION_PAGE_URL, subscriptionCTAHandle, navigateTo)}
          >
            Subscribe
          </Button>
        </div>
      </div>
      <button styleName="close-button" onClick={() => subscriptionCTAHandle({ clickedCTA: false, isOpen: false })} />
    </div>
  ) : null;
};

SubscriptionCTACopyBase.propTypes = {
  userSubscription: PropTypes.object,
  subscriptionCTAHandle: PropTypes.func,
  navigateTo: PropTypes.func
};

function mapStateToProps(state) {
  return {
    userSubscription: get(state, ["showSubscriptionCTA"], {})
  };
}

const mapDispatchToProps = dispatch => ({
  subscriptionCTAHandle: function(payload) {
    dispatch({
      type: SUBSCRIPTION_CTA,
      payload: payload
    });
  },
  navigateTo: url => global.app.navigateToPage(dispatch, url)
});

const SubscriptionCTACopy = connect(mapStateToProps, mapDispatchToProps)(SubscriptionCTACopyBase);
export default SubscriptionCTACopy;

import React from "react";
import { convertHMS } from "../../utils";

export function Timing({ elapsedTimeInSeconds, totalTimeInSeconds }) {
  const formattedElapsedTime = convertHMS(elapsedTimeInSeconds);
  const formattedDuration = convertHMS(totalTimeInSeconds);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <small>{formattedElapsedTime}</small>
      <small>{formattedDuration}</small>
    </div>
  );
}

/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { getTrackFromEpisode } from "../../utils";
import {
  // AddToQueue,
  // DownloadIcon,
  PauseButton,
  PlayButton,
  // PresentInQueueIcon,
  // ArrowUp,
  // ArrowDown,
  CloseIcon
} from "./icons";
import { Button } from "../atoms/button";
import { Loader } from "./Loader";
import { PlayerContext } from "./player-context";
import assetify from "@quintype/framework/assetify";
import get from "lodash/get";
// import { Link } from "@quintype/components";
import { getAuthUrl } from "../helper/hooks";
import podcastImg from "../../../assets/images/nl-images/podcast-banner-on-homepage.png";
import "./show-list.m.css";
import { SubscriberOnlyEpisode } from "./subscriber-only-episode";

export function EpisodeItem({
  episode,
  podcast,
  podcastTitle,
  inQueueComponent,
  hasActiveSubscription
}) {
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const member = useSelector(state => get(state, ["member"], null));

  const authUrl = getAuthUrl();
  // console.log({ episode, podcast });

  const playerContext = useContext(PlayerContext);
  // const currentTrack = playerContext.getCurrentTrack();
  const { currentlyPlaying } = playerContext;

  const addTrackToQueueAndPlayTrack = async data => {
    await playerContext.playTrack(data);
  };

  const { fetchSubscription } = useSelector(state => state);

  const pushToDataLayer = (event, timeStamp) => {
    const obj = {
      event: event,
      podcast_name: currentlyPlaying.title,
      podcast_creator: currentlyPlaying.showTitle,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    if (timeStamp) {
      obj.podcast_completion = Math.ceil(timeStamp);
      obj.max_podcast_duration = Math.ceil(Number(currentlyPlaying.duration));
    }

    window.dataLayer.push(obj);
  };

  const handlePlay = async episode => {
    if (!member) {
      setShowLoginPrompt(true);
    } else {
      const track = getTrackFromEpisode(episode, podcast);

      if (playerContext.currentTrackId === track._id) {
        pushToDataLayer("podcast_play", playerContext.currentTime);

        await playerContext.playTrack(track);
      } else {
        addTrackToQueueAndPlayTrack(track);
        playerContext.setCurrentScreen(null);
      }
    }
  };

  const handlePause = () => {
    pushToDataLayer("podcast_pause", playerContext.currentTime);
    playerContext.pause();
  };
  const isTrackPlaying = trackId =>
    playerContext.audioStatus === "playing" &&
    playerContext.currentTrackId === trackId;
  const isTrackLoading = trackId =>
    playerContext.audioStatus === "loading" &&
    playerContext.currentTrackId === trackId;

  const isSubscriberOnly = episode.status === "draft";
  const showLockIcon = !hasActiveSubscription && isSubscriberOnly;

  const renderPlayButton = episode => {
    if (isTrackPlaying(episode._id)) {
      return (
        <button
          aria-label="Pause"
          title="Pause"
          styleName="icon-btn play-btn"
          className="pressable"
          onClick={handlePause}
        >
          Pause <PauseButton width={20} fill={"#e3e2e2"} />
        </button>
      );
    } else if (isTrackLoading(episode._id)) {
      return (
        <button
          aria-label="loading"
          aria-disabled={true}
          title="Loading"
          styleName="icon-btn play-btn"
          className="pressable"
        >
          <Loader />
        </button>
      );
    } else {
      return (
        <button
          aria-label={"Play " + episode.title}
          title="Play"
          styleName="icon-btn play-btn"
          className="pressable"
          onClick={() => handlePlay(episode)}
        >
          Play <PlayButton width={18} fill={"#e3e2e2"} />
        </button>
      );
    }
  };

  if (showLockIcon) {
    return <SubscriberOnlyEpisode episode={episode} />;
  }

  return (
    <article>
      <section>
        <div styleName="show_list_meta">
          <div styleName="show_list_meta_text">
            <div>{episode.title}</div>
            {podcastTitle && (
              <div styleName="show_list_meta_text--podcast-title">
                {podcastTitle}
              </div>
            )}
            <div styleName="action-button-wrapper">
              {renderPlayButton(episode)}

              {/* {playerContext.isTrackPresentInQueue(episode._id) ? (
                <button
                  disabled={isTrackLoaded(episode._id)}
                  className="pressable icon-btn"
                  styleName="icon-btn"
                  title="Queued"
                  onClick={() =>
                    playerContext.removeTrackFromQueue(episode._id)
                  }
                >
                  <PresentInQueueIcon width={28} fill={"#f26367"} />
                </button>
              ) : (
                <button
                  aria-label="Add to Queue"
                  className="pressable"
                  styleName="icon-btn"
                  title="Add to Queue"
                  onClick={() =>
                    playerContext.addTrackToQueue(
                      getTrackFromEpisode(episode, podcast)
                    )
                  }
                >
                  <AddToQueue width={28} fill={"#e3e2e2"} />
                </button>
              )} */}
              {/* {inQueueComponent ? (
                <button
                  aria-label="Move Up"
                  className="pressable"
                  styleName="icon-btn"
                  title="Move Up"
                  onClick={() => playerContext.moveUpQueue(episode)}
                >
                  <ArrowUp width={28} fill={"#e3e2e2"} />
                </button>
              ) : null}
              {inQueueComponent ? (
                <button
                  aria-label="Move down"
                  className="pressable"
                  styleName="icon-btn"
                  title="Move Down"
                  onClick={() => playerContext.moveDownQueue(episode)}
                >
                  <ArrowDown width={28} fill={"#e3e2e2"} />
                </button>
              ) : null} */}
            </div>
            {showLoginPrompt ? (
              <div styleName="wrapper">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="pressable"
                    onClick={() => setShowLoginPrompt(false)}
                  >
                    <CloseIcon fill="white" width={20}></CloseIcon>
                  </button>
                </div>

                <img
                  styleName="img"
                  src={assetify(podcastImg)}
                  alt="newslaundry logo"
                />
                <h4 styleName="headline">
                  {" "}
                  Oops! We need you to login to access this feature
                </h4>
                <div styleName="content">
                  Sign in now for a seamless experience
                </div>
                <div styleName="button">
                  <Button className="nl-btn-large" href={authUrl}>
                    Login
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </article>
  );
}

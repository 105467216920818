export const campaignMerchandise = {
  Soldier: [],
  Defender: [
    {
      productId: 6938100957350,
      variantId: 40813153943718,
      name: "Laptop Sticker Set",
      subscriptionPrice: 500
    }
  ],
  Bishop: [
    {
      productId: 7684157931686,
      variantId: 43956767752358,
      name: "NL Cap",
      subscriptionPrice: 2500
    }
  ],
  "Truth-seeker": [
    {
      productId: 6938100957350,
      variantId: 40813153943718,
      name: "Laptop Sticker Set",
      subscriptionPrice: 1000
    }
  ],
  Knight: [
    {
      productId: 7403260608678,
      variantId: 42850714583206,
      name: "'I pay to keep news free' Backpack",
      subscriptionPrice: 11000
    }
  ],
  Patron: [
    {
      productId: 7403260608678,
      variantId: 42850714583206,
      name: "'I pay to keep news free' Backpack",
      subscriptionPrice: 12000
    },
    {
      productId: 6938100957350,
      variantId: 40813153943718,
      name: "Laptop Sticker Set",
      subscriptionPrice: 12000
    }
  ],
  Liberator: [
    {
      productId: 6938100957350,
      variantId: 40813153943718,
      name: "Laptop Sticker Set",
      subscriptionPrice: 6000
    },
    {
      productId: 7684157931686,
      variantId: 43956767752358,
      name: "NL Cap",
      subscriptionPrice: 6000
    }
  ],
  // no
  Rook: [
    {
      productId: 7684157931686,
      variantId: 43956767752358,
      name: "NL Cap",
      subscriptionPrice: 20000
    },
    {
      productId: 7403260608678,
      variantId: 42850714583206,
      name: "'I pay to keep news free' Backpack",
      subscriptionPrice: 20000
    }
  ],
  // no
  "King/Queen": [
    {
      productId: 7684157931686,
      variantId: 43956767752358,
      name: "NL Cap",
      subscriptionPrice: 30000
    },
    {
      productId: 7295788187814,
      variantId: 42310157598886,
      name: "NL Sena Mug",
      subscriptionPrice: 30000
    },
    {
      productId: 7403260608678,
      variantId: 42850714583206,
      name: "'I pay to keep news free' Backpack",
      subscriptionPrice: 30000
    }
  ],
  Grandmaster: [
    {
      productId: 7295788187814,
      variantId: 42310157598886,
      name: "NL Sena Mug",
      subscriptionPrice: 50000
    },
    {
      productId: 7684157931686,
      variantId: 43956767752358,
      name: "NL Cap",
      subscriptionPrice: 50000
    },
    // no
    {
      productId: 7403260608678,
      variantId: 42850714583206,
      name: "'I pay to keep news free' Backpack",
      subscriptionPrice: 50000
    },
    {
      productId: 5935229534374,
      variantId: 37020466970790,
      name: "Amar Bari Tomar Bari Naxalbari Comic Reprint",
      subscriptionPrice: 50000
    }
  ],
  "Independent News Guardian": [
    {
      productId: 7684157931686,
      variantId: 43956767752358,
      name: "NL Cap",
      subscriptionPrice: 21000
    },
    {
      productId: 6938100957350,
      variantId: 40813153943718,
      name: "Laptop Sticker Set",
      subscriptionPrice: 21000
    }
  ]
};

export const IS_OPEN_LOGIN_FORM = "IS_OPEN_LOGIN_FORM";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const PAYMENT_CHECKOUT_PAGE = "PAYMENT_CHECKOUT_PAGE";
export const FAVORATE_SONG = "FAVORATE_SONG";
export const PODCAST_CLICK = "PODCAST_CLICK";
export const IS_SUBSCRIPTION_CANCEL = "IS_SUBSCRIPTION_CANCEL";
export const SET_TRACK = "SET_TRACK";
export const SET_PLAYING = "SET_PLAYING";
export const USER_SUBSCRIPTION = "USER_SUBSCRIPTION";
export const USER_SUBSCRIPTION_LOADING = "USER_SUBSCRIPTION_LOADING";
export const SUBSCRIPTION_CTA = "SUBSCRIPTION_CTA";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const INTEGRATION_TOKEN = "INTEGRATION_TOKEN";
export const USER_SUBSCRIPTION_REQUESTED = "USER_SUBSCRIPTION_REQUESTED";
export const USER_SUBSCRIPTION_RECEIVED = "USER_SUBSCRIPTION_RECEIVED";
export const USER_LOGOUT_SUBSCRIPTION_CLEAR = "USER_LOGOUT_SUBSCRIPTION_CLEAR";
export const USER_COUNTRY_REQUEST = "USER_COUNTRY_REQUEST";
export const USER_COUNTRY_SUCCESS = "USER_COUNTRY_SUCCESS";
export const USER_COUNTRY_FAILURE = "USER_COUNTRY_FAILURE";

import { Link } from "@quintype/components";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getGlobalPlayerRef } from "../../podcast-player/utils";
// import { MobTMRBanner } from "./mob-tmr-banner";
import { MdOutlineSearch } from "react-icons/md";

import "./bottom-navbar.m.css";

export const BottomNavbarBase = props => {
  const [active, setActive] = useState();
  const [podcastActive, setPodcastActive] = useState(false);
  // const [isHindi, setIsHindi] = useState(false);

  const playerRef = getGlobalPlayerRef();

  const [data] = useState({
    showPlayer: false,
    loadingUserQueue: false,
    playerState: {}
  });

  // useEffect(() => {
  //   setIsHindi(global.location.host.includes("hindi"));
  // }, []);

  useEffect(() => {
    switch (props.currentPath) {
      case "/":
        setActive(1);
        break;
      case "/tv":
        setActive(2);
        break;
      case "/saved-stories":
        setActive(4);
        break;
      default:
        setActive(0);
        break;
    }
  }, [props.currentPath, playerRef]);

  const onPodcastClick = () => {
    setActive(3);
    playerRef.setShowMenu(false);
    playerRef.userClickedMicIcon = true;

    if (data.playerState.showPlayerFullScreen) {
      setPodcastActive(true);
      playerRef.state.togglePlayer();
    } else if (data.playerState.showPlayer) {
      setPodcastActive(true);
      playerRef.setShowPlayerFullScreen(true);
    } else {
      setPodcastActive(true);
      playerRef.setCurrentScreen("library");
    }
  };

  const handeleMenuClick = () => {
    playerRef.setShowMenu(!playerRef.state.showMenu);
  };

  const handleTabClick = index => {
    setActive(index);
    setPodcastActive(false);
    playerRef.setShowMenu(false);
    playerRef.setCurrentScreen("");
    playerRef.setShowPlayerFullScreen(false);
  };

  return (
    <React.Fragment>
      <nav styleName="bottom-nav-wrapper">
        <div styleName="tabs">
          <ul>
            <li>
              <Link
                styleName={`tab ${active === 1 ? "red" : ""}`}
                aria-label="Click to go to home page"
                href="/"
              >
                <i
                  className="material-icons-outlined "
                  onClick={() => handleTabClick(1)}
                  aria-hidden="true"
                >
                  home
                </i>
                Home
              </Link>
            </li>
            <li>
              <Link
                styleName={`tab ${active === 2 ? "red" : ""}`}
                aria-label="Click to go to video page"
                href="/tv"
              >
                <i
                  className="material-icons-outlined"
                  onClick={() => handleTabClick(2)}
                  aria-hidden="true"
                >
                  ondemand_video
                </i>
                Video
              </Link>
            </li>
            <li>
              <div
                styleName={`tab ${podcastActive ? "red" : ""}`}
                onClick={onPodcastClick}
                aria-label="Click to view podcasts"
              >
                <i className="material-icons" aria-hidden="true">
                  podcasts
                </i>
                Podcast
              </div>
            </li>

            <li>
              <Link
                styleName={`tab ${active === 4 ? "red" : ""}`}
                href={"/search"}
                aria-label="Click to view saved stories"
              >
                <MdOutlineSearch
                  onClick={() => handleTabClick(4)}
                  aria-hidden="true"
                  style={{ width: "1.5rem", height: "1.5rem", flexShrink: 0 }}
                />
                Search
              </Link>
            </li>

            <li>
              <div
                styleName={`tab ${data.playerState.showMenu ? "red" : ""}`}
                onClick={handeleMenuClick}
                aria-label="Click to see other options"
              >
                <i
                  className="material-icons-outlined"
                  onClick={() => handleTabClick(5)}
                  aria-hidden="true"
                >
                  widgets
                </i>
                More
              </div>
            </li>
          </ul>
        </div>
        {/* <MobTMRBanner /> */}
        {/* {!isHindi ? <MobTMRBanner /> : null} */}
      </nav>
    </React.Fragment>
  );
};

BottomNavbarBase.propTypes = {
  menu: PropTypes.object,
  currentPath: PropTypes.object
};

function mapStateToProps(state) {
  return {
    menu: get(state, ["qt", "data", "navigationMenu"], []),
    currentPath: get(state, ["qt", "currentPath"], "")
  };
}

export const BottomNavbar = connect(mapStateToProps, {})(BottomNavbarBase);

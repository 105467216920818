import React from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
// import { fetchIntegrationToken } from "../helper/api";
import {
  clearUserSubscriptionData,
  getUserCountry,
  setUserSubscriptionData
} from "../helper/hooks";

export const GlobalComponent = props => {
  const member = useSelector(state => get(state, ["member"], null));
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!member) {
      clearUserSubscriptionData(dispatch);
    }
  }, [member]);

  React.useEffect(() => {
    // fetchIntegrationToken(dispatch);
    setUserSubscriptionData(dispatch);
    getUserCountry(dispatch);
  }, []);

  return <React.Fragment />;
};

/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";
import assetify from "@quintype/framework/assetify";
import subscriptionCTA from "../../../assets/images/nl-images/subscription-CTA.png";
import { AddToQueue, CloseIcon, DownloadIcon, LockIcon, PlayButton } from "./icons";

import "./show-list.m.css";
import { Button } from "../atoms/button";
import { useDispatch } from "react-redux";
import { SUBSCRIPTION_PAGE_URL } from "../../constants";
import { PlayerContext } from "./player-context";

export function SubscriberOnlyEpisode({ episode }) {
  const dispatch = useDispatch();
  const playerContext = useContext(PlayerContext);
  const [showCTA, setShowCTA] = useState(false);

  const navigateToSubscriptionPage = () => {
    playerContext.setCurrentScreen(null);
    playerContext.setShowPlayerFullScreen(false);
    global.app.navigateToPage(dispatch, SUBSCRIPTION_PAGE_URL + `?ref=player`);
  };

  return (
    <article>
      <section>
        <div styleName="show_list_meta" style={{ flexDirection: "column" }}>
          <div styleName="show_list_meta_text">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{episode.title}</div>
              <div>
                <LockIcon width={24} fill="#ec2227" />
              </div>
            </div>
            <div styleName="action-button-wrapper">
              <button
                aria-label="Please subscribe to play the episode"
                styleName="icon-btn play-btn"
                className="pressable"
                onClick={() => setShowCTA(true)}
              >
                Play <PlayButton width={18} fill={"#e3e2e2"} />
              </button>
              <button
                aria-label="Please subscribe to play the episode"
                className="pressable"
                styleName="icon-btn"
                onClick={() => setShowCTA(true)}
              >
                <AddToQueue width={28} fill={"#e3e2e2"} />
              </button>
              <button
                aria-label="Please subscribe to play the episode"
                className="pressable"
                styleName="icon-btn"
                onClick={() => setShowCTA(true)}
              >
              </button>
            </div>
            {showCTA ? (
              <div styleName="wrapper">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button className="pressable" onClick={() => setShowCTA(false)}>
                    <CloseIcon fill="white" width={20}></CloseIcon>
                  </button>
                </div>

                <img styleName="image" src={assetify(subscriptionCTA)} alt="newslaundry logo" />
                <h4 styleName="headline">Pay to keep news free</h4>
                <div styleName="content">
                  Oops! This is available for subscribers only. Pay to keep news free and get access to paywall
                  podcasts.
                </div>
                <div styleName="button">
                  <Button className="nl-btn-large" onClick={navigateToSubscriptionPage}>
                    Subscribe
                  </Button>
                </div>
              </div>
            ) : null}
            {/* //{" "}
            <div styleName="wrapper">
              //{" "}
              <div styleName="content-wrapper">
                //{" "}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  // <h4 styleName="headline">Pay to keep news free</h4>
                  //{" "}
                  <button className="pressable" onClick={() => setShowCTA(false)}>
                    // <CloseIcon fill="#" width={24}></CloseIcon>
                    //{" "}
                  </button>
                  //{" "}
                </div>
                //{" "}
                <div styleName="content">
                  // This is available for subscribers only. Pay to keep news free and get access to paywall podcasts.
                  //{" "}
                </div>
                //{" "}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  //{" "}
                  <div styleName="button">
                    //{" "}
                    <Button className="nl-btn-large" onClick={navigateToSubscriptionPage}>
                      // Subscribe //{" "}
                    </Button>
                    //{" "}
                  </div>
                  //{" "}
                  <div>
                    // <img styleName="image" src={assetify(subscriptionCTA)} alt="newslaundry logo" />
                    //{" "}
                  </div>
                  //{" "}
                </div>
                //{" "}
              </div>
              //{" "}
            </div>
            // ) : null} */}
          </div>
        </div>
      </section>
    </article>
  );
}

/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import {
  Forward,
  PauseButton,
  PlayButton,
  LibraryIcon,
  Rewind,
  ArrowUp,
  ShowNotes
  // DownloadIcon,
} from "./icons";
import { Loader } from "./Loader";
import "./mobile-player-full-screen.m.css";
import { MovingText } from "./moving-text";
import { PlaySpeedSelect } from "./play-speed-select";
import { PlayerContext } from "./player-context";
import { PlayerModal } from "./player-modal";
import { PlayerProgressBar } from "./player-progress-bar";
import { Timing } from "./timings";

export function MobilePlayerFullScreen({ toggleFullScreen, showFullScreen }) {
  const playerState = useContext(PlayerContext);
  const { currentlyPlaying } = playerState;
  const { member, fetchSubscription } = useSelector(state => state);

  if (!currentlyPlaying) {
    return null;
  }

  const pushToDataLayer = (event, timeStamp) => {
    const obj = {
      event: event,
      podcast_name: currentlyPlaying.title,
      podcast_creator: currentlyPlaying.showTitle,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    if (timeStamp) {
      obj.podcast_completion = Math.ceil(timeStamp);
      obj.max_podcast_duration = Math.ceil(Number(currentlyPlaying.duration));
    }

    window.dataLayer.push(obj);
  };

  const renderPlayButton = () => {
    if (playerState.audioStatus === "playing") {
      return (
        <button
          aria-label="Pause"
          onClick={() => {
            pushToDataLayer("podcast_pause", playerState.currentTime);
            playerState.pause();
          }}
          className="pressable"
          styleName="player-control play-button"
        >
          <PauseButton width="32" />
        </button>
      );
    } else if (playerState.audioStatus === "loading") {
      return (
        <button
          className="pressable"
          styleName="player-control play-button"
          aria-disabled={true}
          aria-label="loading"
        >
          <Loader />
        </button>
      );
    }

    return (
      <button
        onClick={() => {
          pushToDataLayer("podcast_play", playerState.currentTime);
          playerState.play();
        }}
        className="pressable"
        styleName="player-control play-button"
        aria-label="Play"
      >
        <PlayButton width="30" />
      </button>
    );
  };

  return (
    <PlayerModal
      label="Player full screen"
      show={showFullScreen}
      handleClose={toggleFullScreen}
      overlayClassName="mobile-full-screen-overlay"
    >
      <div
        styleName="mobile_player_full_screen"
        // style={currentTrack.podcast.style}
      >
        <div styleName="mobile_player_full_screen_row-1">
          <button
            className="pressable"
            onClick={toggleFullScreen}
            style={{ transform: "rotate(180deg)" }}
          >
            <ArrowUp width={18} />
          </button>
          {/* <a href={currentTrack.streamUrl} download className="pressable">
            <DownloadIcon width={18} />
          </a> */}
        </div>
        <div styleName="mobile_player_full_screen_artwork">
          <img alt="podcast episode artwork" src={currentlyPlaying.cover}></img>
        </div>
        <div>
          <div styleName="mobile_player_full_screen_title">
            <MovingText>{currentlyPlaying.title}</MovingText>
          </div>
          <div>{currentlyPlaying.showTitle}</div>
        </div>

        <div>
          <PlayerProgressBar
            value={playerState.currentTime}
            max={Number(currentlyPlaying.duration)}
            handleSeekChange={playerState.seek}
          />
          <div style={{ height: "1rem" }}></div>
          <Timing
            elapsedTimeInSeconds={playerState.currentTime}
            max={Number(currentlyPlaying.duration)}
            totalTimeInSeconds={Number(currentlyPlaying.duration)}
          />
        </div>

        <div styleName="mobile_player_full_screen_controls">
          <button
            onClick={() => playerState.seek(playerState.currentTime - 15)}
            styleName="player-control"
            className="pressable"
          >
            <Rewind width={32} />
          </button>
          {renderPlayButton()}
          <button
            onClick={() => playerState.seek(playerState.currentTime + 30)}
            styleName="player-control"
            className="pressable"
          >
            <Forward width={32} />
          </button>
        </div>
        <div styleName="mobile_player_full_screen_row-1">
          <PlaySpeedSelect
            value={playerState.playbackRate}
            handleChange={value => playerState.changePlaybackSpeed(value)}
          />
          <button className="pressable" onClick={playerState.toggleShowNotes}>
            <ShowNotes width={26} />
          </button>

          {/* <button
            className="pressable"
            onClick={() => playerState.setCurrentScreen("queue")}
          >
            <QueueIcon width={34} />
          </button> */}
          <button
            className="pressable"
            onClick={() => playerState.setCurrentScreen("library")}
          >
            <LibraryIcon width={28} />
          </button>
          {/* <button className="pressable">
          <ShareIcon width={18} />
        </button> */}
        </div>
      </div>
    </PlayerModal>
  );
}

import format from "date-fns/format";
import get from "lodash/get";
import { useState, useEffect } from "react";
export function isDesktopSizeViewport() {
  return (
    typeof window !== "undefined" &&
    window.matchMedia("(min-width: 1024px)").matches
  );
}
export function dateFormatter(value, unit, suffix, date) {
  return format(new Date(date), "DD MMM YYYY, HH:mm ");
}
export function dateFormatterWithoutTime(value, unit, suffix, date) {
  return format(new Date(date), "dd MMM, yyyy");
}
export function dateFormatterWithOnlyTime(value, unit, suffix, date) {
  return format(new Date(date), " HH:mm");
}
export function getLiveBlogDateFormat(value, unit, suffix, date) {
  if (new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
    return format(new Date(date), " hh:mm A");
  }
  return `${format(new Date(date), " hh:mm A")} | ${format(
    new Date(date),
    "dd MMM, yyyy"
  )}`;
}
function getStoriesByCollection(collection = []) {
  const stories = get(collection, ["items"], []).filter(
    (item) => item.type === "story"
  );
  return stories.map(({ story }) => story);
}
export default getStoriesByCollection;
export function getTextToSlug(text = "") {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}
export const getSlug = (collection, config) => {
  const isSection = collection.template === "section";
  if (isSection) {
    const sectionId = get(collection, ["metadata", "section", "0", "id"], "");
    const sections = config.sections || [];
    const section = sections.find((section) => section.id === sectionId);
    return section ? section["section-url"] : "/";
  }
  return `/collection/${collection.slug}`;
};
export function isValidEmail(email) {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!re.test(email)) return false;
  if (email.length > 150 || email.length < 6) return false;
  return true;
}
export function getStoryHeading(story = {}) {
  return get(story, ["alternative", "home", "default", "headline"])
    ? get(story, ["alternative", "home", "default", "headline"])
    : get(story, "headline");
}
export function getHeroImage(story, config) {
  return (
    get(story, [
      "alternative",
      "home",
      "default",
      "hero-image",
      "hero-image-s3-key",
    ]) ||
    get(story, ["hero-image-s3-key"]) ||
    get(config, ["publisher", "publisher", "defaulImgS3key"])
  );
}
export function getStoryMetadata(story = {}) {
  return (
    get(story, [
      "alternative",
      "home",
      "default",
      "hero-image",
      "hero-image-metadata",
    ]) || story["hero-image-metadata"]
  );
}
export function useScript(src) {
  const [status, setStatus] = useState(src ? "loading" : "idle");
  useEffect(() => {
    if (!src) {
      setStatus("idle");
      return;
    }
    let script = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.setAttribute("data-status", "loading");
      document.body.appendChild(script);
      const setAttributeFromEvent = (event) => {
        script.setAttribute(
          "data-status",
          event.type === "load" ? "ready" : "error"
        );
      };
      script.addEventListener("load", setAttributeFromEvent);
      script.addEventListener("error", setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute("data-status"));
    }
    const setStateFromEvent = (event) => {
      setStatus(event.type === "load" ? "ready" : "error");
    };
    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);
    return () => {
      if (script) {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);
      }
    };
  }, [src]);
  return status;
}
export const getTrackFromEpisode = (episode, podcast) => {
  const track = { ...episode, cover: episode.cover || podcast.cover, podcast };
  return track;
};
export function convertHMS(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
}
export const campaignDescriptionParser = (campaign) => {
  let description = {};
  try {
    description = JSON.parse(campaign.description);
    return {
      ...campaign,
      description,
    };
  } catch (e) {
    console.error("Campaign description is not a valid JSON ", campaign);
    console.log(e);
    return {
      ...campaign,
      description,
    };
  }
};
export const isMobileValid = (mobileNumber) => {
  if (!mobileNumber) {
    return false;
  }
  if (String(mobileNumber).length < 11) {
    return false;
  }
  if (isNaN(Number(mobileNumber))) {
    return false;
  }
  return true;
};
export const getLastNDigits = (number, n) => {
  return Number(
    String(number).slice(String(number).length - n, String(number).length)
  );
};
export const getDigitsExceptFirstN = (number, n) => {
  return Number(String(number).slice(n, String(number).length));
};
export const getDigitsExceptLastN = (number, n) => {
  return Number(String(number).slice(0, String(number).length - n));
};
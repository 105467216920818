import React, { useContext } from "react";
import { DesktopBottomPlayer } from "./desktop-bottom-player";
import { MobileBottomPlayer } from "./mobile-bottom-player";
import { PlayerContext } from "./player-context";
import "./bottom-player.m.css";

export function BottomPlayer() {
  const playerState = useContext(PlayerContext);

  const currentTrack = playerState.getCurrentTrack();

  if (currentTrack) {
    const minsLeft = parseInt(
      Math.max(0, currentTrack.duration - playerState.currentTime) / 60
    );

    return (
      <>
        <div styleName="bottom-player-desktop">
          <DesktopBottomPlayer handleClose={playerState.togglePlayer} />
        </div>
        <div styleName="bottom-player-mobile">
          <MobileBottomPlayer
            track={currentTrack}
            status={playerState.audioStatus}
            handlePause={playerState.pause}
            handlePlay={playerState.play}
            handleClose={playerState.togglePlayer}
            minsLeft={minsLeft}
          />
        </div>
      </>
    );
  }

  return null;
}

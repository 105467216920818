import React from "react";
import PT from "prop-types";
import ReactModal from "react-modal";
// import { CloseIcon } from "../../atoms/close-icon";
import { Button } from "../../atoms/button";

import "./modal.m.css";

export class Modal extends React.Component {
  render() {
    return (
      <ReactModal
        className="nl-modal"
        overlayClassName="modal-backdrop"
        isOpen={true}
        onRequestClose={this.props.onBackdropClick}
      >
        <div className="modal-content">
          {!this.props.hideCloseIcon && (
            // <button styleName="close-button" onClick={this.props.onBackdropClick}>
            //   <CloseIcon />
            // </button>
            <Button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                border: "none",
                outline: "none",
                cursor: "pointer"
              }}
              onClick={this.props.onBackdropClick}
            >
              Skip
            </Button>
          )}
          {this.props.children}
        </div>
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  onBackdropClick: PT.func,
  children: PT.object,
  hideCloseIcon: PT.bool
};

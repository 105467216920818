import React, { useEffect, useState } from "react";

import assetify from "@quintype/framework/assetify";
// import DesktopLogo from "../../../../assets/images/NL-new-logo.svg";
// import HindiLogo from "../../../../assets/images/hindi-logo.png";
import MobileLogo from "../../atoms/icons/mobile-logo";

// import EnglishLightLogo from "../../../../assets/images/nl_english_white.png";
// import EnglishDarkLogo from "../../../../assets/images/nl_english_black.png";
// import HindiLightLogo from "../../../../assets/images/nl_hindi_white.png";
// import HindiDarkLogo from "../../../../assets/images/nl_hindi_black.png";

import HindiWhiteLogo from "../../../../assets/images/hindi_transparent_logo.png";
import EnglishWhiteLogo from "../../../../assets/images/english_transparent_logo.png";
import EnglishBlackLogo from "../../../../assets/images/dark_english_transparent_logo.png";
import HindiBlackLogo from "../../../../assets/images/dark_hindi_transparent_logo.png";

import { useSettingsStore } from "../../../context/useSettingsState";
import "./publihser-logo.m.css";

export function Logo() {
  const [isHindi, setIsHindi] = useState(false);

  const themeState = useSettingsStore(state => state.theme);
  const [calculatedTheme, setCalculatedTheme] = useState(themeState);

  React.useEffect(() => {
    setIsHindi(global.location.host.includes("hindi."));
  }, []);

  useEffect(() => {
    if (themeState === "system") {
      const isDarkTheme = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setCalculatedTheme(isDarkTheme ? "dark" : "light");
    } else {
      setCalculatedTheme(themeState);
    }
  }, [themeState]);

  return (
    <React.Fragment>
      <div className="desktop-only ">
        {isHindi ? (
          calculatedTheme === "dark" ? (
            <img
              src={assetify(HindiBlackLogo)}
              loading="eager"
              alt="Newslaundry"
              styleName="logo-img hindi-logo"
              // width="160px"
              // height="20px"
            />
          ) : (
            <img
              src={assetify(HindiWhiteLogo)}
              loading="eager"
              alt="Newslaundry"
              styleName="logo-img hindi-logo"
              // width="160px"
              // height="20px"
            />
          )
        ) : calculatedTheme === "dark" ? (
          <img
            src={assetify(EnglishBlackLogo)}
            loading="eager"
            alt="Newslaundry"
            styleName="logo-img"
            // width="160px"
            // height="20px"
          />
        ) : (
          <img
            src={assetify(EnglishWhiteLogo)}
            loading="eager"
            alt="Newslaundry"
            styleName="logo-img"
            // width="160px"
            // height="20px"
          />
        )}
      </div>
      <div className="mobile-only" styleName="logo-margin">
        <MobileLogo />
      </div>
    </React.Fragment>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useEscapeKey, useOnClickOutside } from "./utils";

import "./play-speed-select.m.css";

export function PlaySpeedSelect({ value, handleChange }) {
  const [show, setShow] = useState(false);
  const controlRef = useRef(null);
  const buttonRef = useRef(null);
  const containerElem = useRef(null);

  useOnClickOutside(containerElem, () => {
    if (show) {
      setShow(false);
    }
  });

  useEffect(() => {
    if (show) {
      controlRef.current.focus();
    } else if (show === false) {
      buttonRef.current.focus();
    }
  }, [show]);

  useEscapeKey(() => {
    if (show) {
      setShow(false);
    }
  }, [show]);

  return (
    <div ref={containerElem} styleName="player-speed-wrapper">
      {show && (
        <div styleName="player-speed-controls">
          <input
            ref={controlRef}
            type="range"
            id="speed_control"
            min="0.5"
            max="3"
            value={value}
            step="0.25"
            aria-valuemin="0.5"
            aria-valuemax="3"
            aria-valuenow={value}
            onChange={e => handleChange(parseFloat(e.target.value))}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
              width: "100%",
              alignItems: "center",
              color: "white"
            }}
          >
            <button
              onClick={() => {
                handleChange(1);
                setShow(false);
              }}
              aria-label="Reset playback speed"
              className="pressable"
              style={{ fontSize: "1rem" }}
            >
              Reset
            </button>
            <output htmlFor="speed_control">{value}x</output>
          </div>
        </div>
      )}
      <button
        ref={buttonRef}
        aria-label="Change player speed"
        title="player speed"
        className="pressable"
        onClick={() => setShow(!show)}
      >
        <span>{`${value}x`}</span>
      </button>
    </div>
  );
}

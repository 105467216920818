import React from "react";
import { LoadingIndicator } from "@quintype/components";

import "./loading-indicator.m.css";

const LoadingIndicatorComponent = () => (
  <LoadingIndicator>
    <div styleName="qt-loading-animation">
      <LoadingIcon />
    </div>
  </LoadingIndicator>
);

const LoadingIcon = () => (
  <svg version="1.0" width="64px" height="64px" viewBox="0 0 128 128">
    <g>
      <path d="M59.6 0h8v40h-8V0z" fill="#ec2227" fillOpacity="1" />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#fbd3d4"
        fillOpacity="0.2"
        transform="rotate(30 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#fbd3d4"
        fillOpacity="0.2"
        transform="rotate(60 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#fbd3d4"
        fillOpacity="0.2"
        transform="rotate(90 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#fbd3d4"
        fillOpacity="0.2"
        transform="rotate(120 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f9bcbe"
        fillOpacity="0.3"
        transform="rotate(150 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f7a7a9"
        fillOpacity="0.4"
        transform="rotate(180 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f59093"
        fillOpacity="0.5"
        transform="rotate(210 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f47a7d"
        fillOpacity="0.6"
        transform="rotate(240 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f26467"
        fillOpacity="0.7"
        transform="rotate(270 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f04e52"
        fillOpacity="0.8"
        transform="rotate(300 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#ee383c"
        fillOpacity="0.9"
        transform="rotate(330 64 64)"
      />
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64"
        calcMode="discrete"
        dur="1080ms"
        repeatCount="indefinite"
      ></animateTransform>
    </g>
  </svg>
);

export default LoadingIndicatorComponent;

/* eslint-disable react/prop-types */
import React from "react";

import * as SeparatorPrimitive from "@radix-ui/react-separator";

import { cva } from "class-variance-authority";

const separatorVariants = cva("separator-root", {
  variants: {
    variant: {
      neutral: "separator-variant-neutral",
      accent: "separator-variant-accent",
      success: "separator-variant-success",
      attention: "separator-variant-attention",
      danger: "separator-variant-danger"
    }
  },
  defaultVariants: {
    variant: "neutral"
  }
});

const Separator = React.forwardRef(
  ({ children, variant, orientation, decorative, ...props }, forwardedRef) => {
    const allClassNames = `${separatorVariants({ variant })} ${
      props.className || ""
    } ${props.styleName || ""}`;

    return (
      <SeparatorPrimitive.Root
        ref={forwardedRef}
        orientation={orientation}
        decorative={decorative}
        aria-orientation={decorative ? undefined : orientation}
        {...props}
        className={allClassNames}
      >
        {children}
      </SeparatorPrimitive.Root>
    );
  }
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };

import React from "react";
import PT from "prop-types";

import "./search-icon.m.css";

export default function SearchIcon({
  onClick,
  className,
  height = 24,
  width = 24,
  color = "#FFF"
}) {
  return (
    <div onClick={onClick} styleName={className}>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18 20"
      >
        <g fill="currentColor" fillRule="evenodd" transform="translate(1 1)">
          <ellipse cx="6.434" cy="6.635" rx="6.154" ry="6.346" />
          <path strokeLinecap="square" d="M10.35 11.827l5.452 5.85" />
        </g>
      </svg> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        width={24}
        height={24}
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
        />
      </svg>
    </div>
  );
}

SearchIcon.propTypes = {
  onClick: PT.func,
  className: PT.string,
  width: PT.number,
  height: PT.number,
  color: PT.string
};

import React, { useState } from "react";
import { SocialMediaShoutout } from "../social-media-shoutout";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { IS_OPEN_LOGIN_FORM } from "../../helper/actions";
import { updateLogSubscription } from "../../helper/api";
import { Button } from "../../atoms/button";

import "./sena-success-form.m.css";

export function SenaSuccessForm({ handleClose }) {
  const member = useSelector(state => get(state, ["member"], null));
  const userSubscriptions = useSelector(state =>
    get(state, ["fetchSubscription", "items"], [])
  );

  const lastSubscription = userSubscriptions[0];

  const [data, setData] = useState({
    twitterHandle: "",
    instagramHandle: "",
    userConsentForNLSenaShoutout: "yes",
    userConsentForCreditAsAPatron: "yes",
    loading: false,
    success: false,
    error: "",
    choiceOfMerch: "random",
    address: "",
    phone: "",
    city: "",
    state: "",
    pincode: ""
  });
  const [error, setError] = useState(null);

  const {
    instagramHandle,
    twitterHandle,
    userConsentForNLSenaShoutout,
    loading,
    userConsentForCreditAsAPatron,
    choiceOfMerch,
    address,
    phone,
    city,
    state,
    pincode
  } = data;

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const dispatch = useDispatch();

  const showLoginPopup = () => {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: true
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    console.log(`form submit`);
    if (!lastSubscription) {
      console.log(`no last sub`);
      setError(
        "Something went wrong! Please mail us at subscription@newslaundry.com for further assistance."
      );
      return;
    }

    if (!member) {
      console.log(`no member`);
      await showLoginPopup();
      return;
    }

    setError(null);

    setData({
      ...data,
      loading: true,
      error: null,
      success: false
    });

    try {
      const updateObject = {
        sheetIndex: 0,
        data: {
          member_id: member.id,
          member_name: member.name,
          phone: phone,
          email: member.email,
          subscription_id: lastSubscription.id,
          amount: lastSubscription.payment_amount,
          merch_choice:
            parseInt(lastSubscription.payment_amount) === 1000 ||
            parseInt(lastSubscription.payment_amount) === 2500
              ? choiceOfMerch
              : "NA",
          group_name: lastSubscription.group_name,
          plan_name: lastSubscription.plan_name,
          userConsentForNLSenaShoutout,
          userConsentForCreditAsAPatron,
          instagramHandle,
          twitterHandle,
          address: JSON.stringify({
            address,
            pincode,
            city,
            state
          })
        }
      };
      // console.log(updateObject);

      await updateLogSubscription(updateObject);

      setData({
        ...data,
        loading: false,
        success: true
      });
    } catch (e) {
      console.log(`Big err`, e);
      setData({
        ...data,
        error: e.message,
        loading: false
      });
      setError(
        `Something went wrong! Please mail us at subscription@newslaundry.com for further assistance.`
      );
    }
  };

  return data.success ? (
    <div styleName="close-wrapper">
      <h4 styleName="success-headline">Thank you! Submitted Successfully</h4>
      <Button onClick={handleClose}>Close</Button>
    </div>
  ) : (
    <form styleName="wrapper" onSubmit={handleSubmit}>
      <div styleName="form-group text">
        <div>Can we credit you as a patron in the story?</div>
        <div>
          <label>
            <input
              type="radio"
              name="userConsentForCreditAsAPatron"
              value="yes"
              checked={userConsentForCreditAsAPatron === "yes"}
              onChange={handleChange}
            />{" "}
            Yes
          </label>
          &nbsp;
          <label>
            <input
              type="radio"
              name="userConsentForCreditAsAPatron"
              value="no"
              checked={userConsentForCreditAsAPatron === "no"}
              onChange={handleChange}
            />{" "}
            No
          </label>
        </div>
      </div>
      <SocialMediaShoutout
        name="userConsentForNLSenaShoutout"
        onChange={handleChange}
        values={{
          twitterHandle,
          instagramHandle,
          userConsentForNLSenaShoutout
        }}
      />
      <div styleName="text" style={{ fontWeight: 400 }}>
        The address you would like the Sena goodies to be shipped to (if
        you&apos;re based overseas, please share an Indian address)
      </div>
      {parseInt(lastSubscription && lastSubscription.payment_amount) === 1000 ||
      parseInt(lastSubscription && lastSubscription.payment_amount) === 2500 ? (
        <div styleName="form-group">
          <label htmlFor="address" styleName="text">
            Choice of Merch
          </label>
          <select
            styleName="input-text"
            required
            value={choiceOfMerch}
            id="choiceOfMerch"
            name="choiceOfMerch"
            onChange={handleChange}
          >
            <option value="random">Random Merch</option>
            <option value="mug">NL Mug</option>
            <option value="soap">Sabki Dhulai Soap Set</option>
          </select>
        </div>
      ) : (
        ``
      )}
      <div styleName="form-group">
        <label htmlFor="address" styleName="text">
          Address
        </label>
        <input
          styleName="input-text"
          required
          value={address}
          id="address"
          type="text"
          placeholder="Street address"
          name="address"
          onChange={handleChange}
        ></input>
      </div>
      <div styleName="flex-wrap">
        <div styleName="form-group">
          <label htmlFor="city" styleName="text">
            City
          </label>
          <input
            styleName="input-text"
            required
            value={city}
            id="city"
            type="text"
            placeholder="City"
            name="city"
            onChange={handleChange}
          ></input>
        </div>

        <div styleName="form-group">
          <label htmlFor="state" styleName="text">
            State
          </label>
          <input
            styleName="input-text"
            required
            value={state}
            id="state"
            type="text"
            placeholder="State"
            name="state"
            onChange={handleChange}
          ></input>
        </div>
        <div styleName="form-group">
          <label htmlFor="pincode" styleName="text">
            Pincode
          </label>
          <input
            styleName="input-text"
            required
            value={pincode}
            id="pincode"
            type="text"
            placeholder="Pincode"
            name="pincode"
            onChange={handleChange}
          ></input>
        </div>
      </div>
      <div styleName="form-group">
        <label htmlFor="phone" styleName="text">
          Phone Number
        </label>
        <input
          styleName="input-text"
          required
          value={phone}
          id="phone"
          type="text"
          placeholder="Phone"
          name="phone"
          onChange={handleChange}
        ></input>
      </div>
      {error && <div styleName="error-message">{error}</div>}
      <p styleName="text">
        Please do not disable cookies when submitting the form.
      </p>
      <div style={{ display: "flex", gap: "1.5rem" }}>
        <Button type="submit" disabled={loading}>
          Submit
        </Button>
      </div>
    </form>
  );
}

SenaSuccessForm.propTypes = {
  handleClose: PropTypes.func
};

/* eslint-disable no-undef */
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

// export type ThemeType = "system" | "light" | "dark";
// export type ColourFilterType = "none" | "deuteranopia" | "protanopia" | "tritanopia";
// export type FontSizeType = "small" | "medium" | "large";
// export type DyslexiaModeType = "enabled" | "disabled";
// export type DyslexiaModeType = "disabled" | "enabled";
// export type ReducedMotionType = "system" | "enabled" | "disabled";
// export type SpacingType = "compact" | "default" | "comfortable";
// export type HighContrastType = "system" | "enabled" | "disabled";
// export type UnderlineLinksType = "enabled" | "disabled";
// export type BiggerCursorType = "enabled" | "disabled";

// type SettingsStoreType = {
//   theme: ThemeType;
//   setTheme: (theme: ThemeType) => void;
//   colourFilter: ColourFilterType;
//   setColourFilter: (filter: ColourFilterType) => void;
//   fontSize: FontSizeType;
//   setFontSize: (size: FontSizeType) => void;
//   dyslexiaMode: DyslexiaModeType;
//   setDyslexiaMode: (value: DyslexiaModeType) => void;
//   reducedMotion: ReducedMotionType;
//   setReducedMotion: (value: ReducedMotionType) => void;
//   spacing: SpacingType;
//   setSpacing: (value: SpacingType) => void;
//   highContrast: HighContrastType;
//   setHighContrast: (value: HighContrastType) => void;
//   underlineLinks: UnderlineLinksType;
//   setUnderlineLinks: (value: UnderlineLinksType) => void;
//   biggerCursor: BiggerCursorType;
//   setBiggerCursor: (value: BiggerCursorType) => void;
// };

// const useSettingsStore = create<SettingsStoreType>(set => ({
//   theme: "system",
//   setTheme: theme => set({ theme: theme }),
//   colourFilter: "none",
//   setColourFilter: value => set({ colourFilter: value }),
//   dyslexiaMode: "disabled",
//   setDyslexiaMode: value => set({ dyslexiaMode: value }),
//   reducedMotion: "system",
//   setReducedMotion: value => set({ reducedMotion: value }),
//   spacing: "default",
//   setSpacing: value => set({ spacing: value }),
//   highContrast: "system",
//   setHighContrast: value => set({ highContrast: value }),
//   underlineLinks: "disabled",
//   setUnderlineLinks: value => set({ underlineLinks: value }),
//   biggerCursor: "disabled",
//   setBiggerCursor: value => set({ biggerCursor: value })
// }));

const useSettingsStore = create(
  persist(
    set => ({
      theme: "system",
      setTheme: theme => set({ theme: theme }),
      colourFilter: "none",
      setColourFilter: value => set({ colourFilter: value }),
      fontSize: "medium",
      setFontSize: value => set({ fontSize: value }),
      dyslexiaMode: "disabled",
      setDyslexiaMode: value => set({ dyslexiaMode: value }),
      reducedMotion: "system",
      setReducedMotion: value => set({ reducedMotion: value }),
      spacing: "default",
      setSpacing: value => set({ spacing: value }),
      highContrast: "system",
      setHighContrast: value => set({ highContrast: value }),
      underlineLinks: "disabled",
      setUnderlineLinks: value => set({ underlineLinks: value }),
      biggerCursor: "disabled",
      setBiggerCursor: value => set({ biggerCursor: value })
    }),
    {
      name: "settings-storage",
      storage: createJSONStorage(() => localStorage)
    }
  )
);

export { useSettingsStore };

/* eslint-disable react/prop-types */
import React from "react";

import * as DialogPrimitive from "@radix-ui/react-dialog";

import { X as Cancel } from "lucide-react";
import { Button } from "../button";

const Dialog = ({ children, ...props }) => {
  const allClassNames = `${props.className || ""} ${props.styleName || ""}`;

  return (
    <DialogPrimitive.Root {...props} className={allClassNames}>
      {children}
    </DialogPrimitive.Root>
  );
};

const DialogTrigger = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    const allClassNames = `${props.className || ""} ${props.styleName || ""}`;

    return (
      <DialogPrimitive.Trigger
        ref={forwardedRef}
        {...props}
        className={allClassNames}
      >
        {children}
      </DialogPrimitive.Trigger>
    );
  }
);
DialogTrigger.displayName = DialogPrimitive.Trigger.displayName;

const DialogPortal = ({ children, ...props }) => {
  const allClassNames = `${props.className || ""} ${props.styleName || ""}`;

  return (
    <DialogPrimitive.Portal {...props} className={allClassNames}>
      {children}
    </DialogPrimitive.Portal>
  );
};
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef(({ ...props }, forwardedRef) => {
  const allClassNames = `dialog-overlay ${props.className || ""} ${
    props.styleName || ""
  }`;

  return (
    <DialogPrimitive.Overlay
      ref={forwardedRef}
      {...props}
      className={allClassNames}
    />
  );
});
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    const allClassNames = `dialog-content ${props.className || ""} ${
      props.styleName || ""
    }`;

    return (
      <DialogPortal>
        <DialogOverlay />
        <DialogPrimitive.Content
          ref={forwardedRef}
          aria-modal={true}
          {...props}
          className={allClassNames}
        >
          {children}
          <DialogClose />
        </DialogPrimitive.Content>
      </DialogPortal>
    );
  }
);
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogTitle = React.forwardRef(({ children, ...props }, forwardedRef) => {
  const allClassNames = `radio-group-title ${props.className || ""} ${
    props.styleName || ""
  }`;

  return (
    <DialogPrimitive.Title
      ref={forwardedRef}
      // className={cn(
      //   "max-w-[240px] text-lg font-medium text-neutral-default sm:max-w-none",
      //   className
      // )}
      {...props}
      className={allClassNames}
    >
      {children}
    </DialogPrimitive.Title>
  );
});
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    const allClassNames = `radio-group-description ${props.className || ""} ${
      props.styleName || ""
    }`;

    return (
      <DialogPrimitive.Description
        ref={forwardedRef}
        // className={cn("text-base leading-normal text-neutral-muted", className)}
        {...props}
        className={allClassNames}
      >
        {children}
      </DialogPrimitive.Description>
    );
  }
);
DialogDescription.displayName = DialogPrimitive.Description.displayName;

const DialogClose = React.forwardRef(({ ...props }, forwardedRef) => {
  const allClassNames = `dialog-close ${props.className || ""} ${
    props.styleName || ""
  }`;

  return (
    <DialogPrimitive.Close
      ref={forwardedRef}
      asChild
      {...props}
      className={allClassNames}
    >
      <Button aria-label="close">
        <Cancel
          style={{
            height: "1rem",
            width: "1rem",
            color: "var(--text-neutral-default)",
            flexShrink: 0
          }}
          aria-hidden="true"
        />
      </Button>
      {/* <button
        aria-label="Close"
      >
        <Cancel className="" aria-hidden="true" />
      </button> */}
    </DialogPrimitive.Close>
  );
});
DialogClose.displayName = DialogPrimitive.Close.displayName;

Dialog.Trigger = DialogTrigger;
Dialog.Content = DialogContent;
Dialog.Title = DialogTitle;
Dialog.Description = DialogDescription;

export { Dialog };

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { IS_OPEN_LOGIN_FORM } from "../../helper/actions";
import { updateLogSubscription } from "../../helper/api";
import { Button } from "../../atoms/button";

import "./sena-success-form.m.css";

export function GameChangerForm({ handleClose, mode }) {
  const member = useSelector(state => get(state, ["member"], null));
  const userSubscriptions = useSelector(state =>
    get(state, ["fetchSubscription", "items"], [])
  );

  const lastSubscription = userSubscriptions[0];

  const isGCThreeYear =
    lastSubscription &&
    lastSubscription.duration_unit === "years" &&
    lastSubscription.duration_length === 3;

  const [data, setData] = useState({
    loading: false,
    success: false,
    error: "",
    address: "",
    phone: "",
    city: "",
    state: "",
    pincode: ""
  });
  const [error, setError] = useState(null);

  const { loading, address, phone, city, state, pincode } = data;

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const dispatch = useDispatch();

  const showLoginPopup = () => {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: true
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    console.log(`form submit`);
    if (!lastSubscription) {
      console.log(`no last sub`);
      setError(
        "Something went wrong! Please mail us at subscription@newslaundry.com for further assistance."
      );
      return;
    }

    if (!member) {
      console.log(`no member`);
      await showLoginPopup();
      return;
    }

    setData({
      ...data,
      loading: true,
      error: null,
      success: false
    });

    try {
      const updateObject = {
        sheetIndex: mode || (isGCThreeYear ? 3 : 2),
        data: {
          member_id: member.id,
          member_name: member.name,
          phone: phone,
          email: member.email,
          subscription_id: lastSubscription.id,
          amount: lastSubscription.payment_amount,
          group_name: lastSubscription.group_name,
          plan_name: lastSubscription.plan_name,
          address: JSON.stringify({
            address,
            pincode,
            city,
            state
          })
        }
      };

      await updateLogSubscription(updateObject);

      setData({
        ...data,
        loading: false,
        success: true
      });
      console.log(`Saved on sheet`);
    } catch (e) {
      console.log(`Big err`, e);
      setData({
        ...data,
        error: e.message,
        loading: false
      });
      setError(
        `Something went wrong! Please mail us at subscription@newslaundry.com for further assistance.`
      );
    }
  };

  return data.success ? (
    <div styleName="close-wrapper">
      <h4 styleName="success-headline">Thank you! Submitted Successfully</h4>
      <Button onClick={handleClose}>Close</Button>
    </div>
  ) : (
    <form styleName="wrapper" onSubmit={handleSubmit}>
      <div styleName="text" style={{ fontWeight: 400 }}>
        The address you would like the Newslaundry merch to be shipped to (if
        you&apos;re based overseas, please share an Indian address)
      </div>
      <div styleName="form-group">
        <label htmlFor="address" styleName="text">
          Address
        </label>
        <input
          styleName="input-text"
          required
          value={address}
          id="address"
          type="text"
          placeholder="Street address"
          name="address"
          onChange={handleChange}
        ></input>
      </div>
      <div styleName="flex-wrap">
        <div styleName="form-group">
          <label htmlFor="city" styleName="text">
            City
          </label>
          <input
            styleName="input-text"
            required
            value={city}
            id="city"
            type="text"
            placeholder="City"
            name="city"
            onChange={handleChange}
          ></input>
        </div>

        <div styleName="form-group">
          <label htmlFor="state" styleName="text">
            State
          </label>
          <input
            styleName="input-text"
            required
            value={state}
            id="state"
            type="text"
            placeholder="State"
            name="state"
            onChange={handleChange}
          ></input>
        </div>
        <div styleName="form-group">
          <label htmlFor="pincode" styleName="text">
            Pincode
          </label>
          <input
            styleName="input-text"
            required
            value={pincode}
            id="pincode"
            type="text"
            placeholder="Pincode"
            name="pincode"
            onChange={handleChange}
          ></input>
        </div>
      </div>
      <div styleName="form-group">
        <label htmlFor="phone" styleName="text">
          Phone Number
        </label>
        <input
          styleName="input-text"
          required
          value={phone}
          id="phone"
          type="text"
          placeholder="Phone"
          name="phone"
          onChange={handleChange}
        ></input>
      </div>
      {error && <div styleName="error-message">{error}</div>}
      <p styleName="text">
        Please do not disable cookies when submitting the form.
      </p>
      <Button type="submit" disabled={loading}>
        Submit
      </Button>
    </form>
  );
}

GameChangerForm.propTypes = {
  handleClose: PropTypes.func,
  mode: PropTypes.number
};

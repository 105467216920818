import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

export function PlayerModal({ show, children, handleClose, label, onAfterOpen, overlayClassName }) {
  return (
    <Modal
      onAfterOpen={onAfterOpen}
      closeTimeoutMS={500}
      className="player-modal"
      overlayClassName={overlayClassName || "player-modal-overlay"}
      isOpen={show}
      onRequestClose={handleClose}
      contentLabel={label}
    >
      {children}
    </Modal>
  );
}

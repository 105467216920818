import React from "react";
import { Link } from "@quintype/components";

import "./settings-floating-button.m.css";
import { MdAccessibilityNew } from "react-icons/md";

const SettingFloatingButton = () => {
  return (
    <div styleName="settings-floating-btn-wrapper">
      <Link href="/settings" aria-label="Go to settings page">
        <MdAccessibilityNew aria-hidden="true" />
      </Link>
    </div>
  );
};

export { SettingFloatingButton };

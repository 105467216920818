/* eslint-disable react/prop-types */
// import { query } from "express-validator";
import { get } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { getAcastEpisodes, getAcastShows } from "../helper/api";
import { EpisodeItem } from "./episode-item";
import { BackIcon, CloseIcon } from "./icons";
import { Loader } from "./Loader";

import "./show-list.m.css";

// const shows = require("./shows.json");

export function ShowList({ handleClick, handleClose, currentShow }) {
  const [shows, setShows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchShow = useCallback(async () => {
    setIsLoading(true);
    const showsData = await getAcastShows();

    const filteredShowData = showsData.shows.filter(
      show => show.showId !== "6058d9ed92277c6c1bf3a867"
    );

    setShows(filteredShowData);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchShow();
  }, []);

  return (
    <div styleName="show_list">
      <div styleName="show_list_content">
        <div
          styleName="top_sticky"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            title="Close"
            aria-label="Close"
            onClick={handleClose}
            className="pressable"
          >
            <CloseIcon width={18} />
          </button>
        </div>
        <header>
          <div styleName="heading heading__list">Shows</div>
        </header>
        {isLoading ? (
          <div style={{ width: "100%", marginTop: "2rem" }}>
            <Loader />
          </div>
        ) : (
          shows.map(show => {
            return (
              <article key={show._id}>
                <button onClick={() => handleClick(show)} className="pressable">
                  <section>
                    <div styleName="show_list_meta">
                      <img
                        src={show.cover}
                        styleName="show_list_artwork_cover"
                        alt={show.title}
                        aria-hidden="true"
                      ></img>
                      <div styleName="show_list_meta_text show_list_meta_text--podcast">
                        <div styleName="heading" aria-label={`Show title: ${show.title}`}>{show.title}</div>
                        <div
                          styleName="show_list_meta_title"
                          aria-label={`Show summary: ${show.summary}`}
                          dangerouslySetInnerHTML={{ __html: show.summary }}
                        />
                      </div>
                    </div>
                  </section>
                </button>
              </article>
            );
          })
        )}
        {/* {shows.map(show => {
          return (
            <article key={show._id}>
              <button onClick={() => handleClick(show)} className="pressable">
                <section>
                  <div styleName="show_list_meta">
                    <img
                      src={show.cover}
                      styleName="show_list_artwork_cover"
                      alt={show.title}
                    ></img>
                    <div styleName="show_list_meta_text show_list_meta_text--podcast">
                      <div styleName="heading">{show.title}</div>
                      <div styleName="show_list_meta_title">{show.summary}</div>
                    </div>
                  </div>
                </section>
              </button>
            </article>
          );
        })} */}
      </div>
    </div>
  );
}

async function getEpisodes({ queryKey, pageParam = 1 }) {
  return getAcastEpisodes(queryKey[1].podcastId, pageParam);
}

export function EpisodeList({ podcast, handleBack, handleClose }) {
  const listRef = useRef(null);
  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], [])
  );
  const member = useSelector(state => {
    return get(state, ["member"], null);
  });

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    // isFetching,
    isFetchingNextPage,
    status
  } = useInfiniteQuery(
    ["podcastEpisodes", { podcastId: podcast._id }],
    getEpisodes,
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextPage;
      }
    }
  );

  function renderList() {
    return status === "loading" ? (
      <div style={{ width: "100%", marginTop: "2rem" }}>
        <Loader />
      </div>
    ) : status === "error" ? (
      <p>Error: {error.message}</p>
    ) : (
      data.pages.map((page, i) => {
        return (
          <React.Fragment key={i}>
            {page.body.episodes.map(episode => {
              return (
                <EpisodeItem
                  key={episode._id}
                  episode={episode}
                  podcast={podcast}
                  hasActiveSubscription={hasActiveSubscription}
                  member={member}
                />
              );
            })}
          </React.Fragment>
        );
      })
    );
  }

  return (
    <div ref={listRef} styleName="show_list">
      <div styleName="show_list_content">
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          styleName="top_sticky"
        >
          <button
            title="Go back to shows"
            aria-label="Go back to show list"
            onClick={handleBack}
            className="pressable"
          >
            <BackIcon width={32} />
          </button>
          <button
            title="Close"
            aria-label="Close"
            onClick={handleClose}
            className="pressable"
          >
            <CloseIcon width={18} />
          </button>
        </div>
        <header styleName="header">
          <div style={{ marginRight: "1rem" }}>
            <div
              style={{ fontSize: "1.5rem" }}
              styleName="heading heading__list"
            >
              {podcast.title}
            </div>
            <div
              styleName="header_summary"
              dangerouslySetInnerHTML={{ __html: podcast.summary }}
            />
          </div>
          <img
            src={podcast.cover}
            styleName="header__artwork"
            alt="podcast cover"
          ></img>
        </header>
        <ScrollView
          listRef={listRef}
          onEndReached={hasNextPage && fetchNextPage}
        >
          {renderList()}
        </ScrollView>
        <div style={{ textAlign: "center" }}>
          {isFetchingNextPage ? "Loading more episodes..." : null}
        </div>
      </div>
    </div>
  );
}

const ScrollView = ({ onEndReached, children, listRef }) => {
  const called = useRef(false);

  useEffect(() => {
    if (listRef) {
      const elem = listRef.current;
      elem.addEventListener("scroll", () => {
        if (elem.offsetHeight + elem.scrollTop + 400 >= elem.scrollHeight) {
          if (!called.current) {
            onEndReached();
            called.current = true;
          }
          setTimeout(() => {
            called.current = false;
          }, 100);
        }
      });
    }
  }, [listRef, onEndReached]);
  return children;
};

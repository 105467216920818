import React from "react";
import PropTypes from "prop-types";
import assetify from "@quintype/framework/assetify";
import whatsapp from "../../../../assets/images/whatsapp.svg";

import "./show-success-msg.m.css";
import { TwitterIcon } from "../../atoms/icons/twitter-icon";
import { FbIcon } from "../../atoms/icons/fb-icon";
import { LinkedinIcon } from "../../atoms/icons/linkedin-icon";

const ShareSuccessMessage = props => {
  return (
    <ul styleName="share-success-message">
      <li>
        <a href={props.fbUrl} target="_blank" rel="noopener noreferrer">
          <FbIcon />
        </a>
      </li>
      <li>
        <a href={props.twitterUrl} target="_blank" rel="noopener noreferrer">
          <TwitterIcon />
        </a>
      </li>
      <li>
        <a href={props.whatsappUrl} target="_blank" rel="noopener noreferrer">
          <img src={assetify(whatsapp)} alt="whatsapp" />
        </a>
      </li>

      <li>
        <a href={props.linkedinUrl} target="_blank" rel="noopener noreferrer">
          <LinkedinIcon />
        </a>
      </li>
    </ul>
  );
};

ShareSuccessMessage.propTypes = {
  fbUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  whatsappUrl: PropTypes.string,
  linkedinUrl: PropTypes.string
};

export default ShareSuccessMessage;

import {
  renderComponent,
  renderIsomorphicComponent
} from "@quintype/framework/client/start";
import get from "lodash/get";
import LoadingIndicatorComponent from "../isomorphic/components/atoms/loading-indicator";
// import FloatingPodButton from "../isomorphic/components/floating-pod-button";
import { SettingFloatingButton } from "../isomorphic/components/atoms/settings-floating-button";

import { GlobalComponent } from "../isomorphic/components/global-component";
import { Header } from "../isomorphic/components/header";
import { BottomNavbar } from "../isomorphic/components/layouts/bottom-navbar";
import { Footer } from "../isomorphic/components/layouts/footer";
import { ShowSuccessMsg } from "../isomorphic/components/molecules/show-success-msg";
import SubscriptionCTACopy from "../isomorphic/components/molecules/subscription-cta-copy";
import { PlayerContextProvider } from "../isomorphic/components/podcast-player/player-context";
// import { TMRBanner } from "../isomorphic/components/tmr-banner";
import { pickComponent } from "../isomorphic/pick-component";

// import { NewsableBottomSheet } from "../isomorphic/components/newsable-bottom-sheet";

// import { LaunchConfetti } from "../isomorphic/components/launch-confetti";
// import NewsletterBanner from "../isomorphic/components/newsletter-banner";
// import { LoginPage } from "../isomorphic/components/pages/login"
// import OneTapLogin from "../isomorphic/components/one-tap-login";
// import { Banner } from "../isomorphic/components/layouts/banner";

export function preRenderApplication(store) {
  const hydrate = { hydrate: !global.qtLoadedFromShell };
  renderComponent(PlayerContextProvider, "audio-player", store, hydrate);
  const pageType = get(store.getState(), ["qt", "config", "pageType"], null);

  pageType !== "login-page" &&
    renderComponent(Header, "header", store, hydrate);
  renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
  pageType !== "login-page" &&
    renderComponent(Footer, "footer", store, hydrate);
  // pageType !== "login-page" && renderComponent(NewsletterBanner, "newsletter-banner", store, hydrate);
  // pageType !== "login-page" &&
  //   renderComponent(OneTapLogin, "one-tap-login", store, hydrate);
  renderComponent(ShowSuccessMsg, "show-success-msg", store, hydrate);
  // renderComponent(FloatingPodButton, "floating-pod-button", store, hydrate);
  renderComponent(
    SettingFloatingButton,
    "settings-floating-button",
    store,
    hydrate
  );
  // renderComponent(LaunchConfetti, "confetti-launch", store, hydrate);
  // pageType !== "login-page" &&
  // renderComponent(TMRBanner, "tmr-banner", store, hydrate);
  // renderComponent(NewsableBottomSheet, "newsable-bottom-sheet", store, hydrate);
  renderComponent(BottomNavbar, "bottom-navbar", store, hydrate);
  // pageType !== "login-page" && renderComponent(Banner, "banner", store, hydrate);
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  renderComponent(SubscriptionCTACopy, "subscription-cta", store, {
    hydrate: true
  });
  renderComponent(GlobalComponent, "global-component", store, {
    hydrate: true
  });
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell
  });
}

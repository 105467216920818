import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";

import "./button.m.css";

export function Button({
  href,
  children,
  className = "",
  styleName = "",
  color,
  flat,
  style,
  socialButton = false,
  ...props
}) {
  const classNames = `nl-btn ${className} ${styleName}`;
  const inlineStyle = {
    borderColor: color,
    backgroundColor: flat && "transparent",
    color: color,
    minHeight: "30px",
    ...style
  };
  if (socialButton) {
    return (
      <a href={href} styleName={classNames} style={inlineStyle} {...props}>
        {children}
      </a>
    );
  }
  return href ? (
    <Link href={href} styleName={classNames} style={inlineStyle} {...props}>
      {children}
    </Link>
  ) : (
    <button styleName={classNames} style={inlineStyle} {...props}>
      {children}
    </button>
  );
}

Button.propTypes = {
  href: PT.string,
  className: PT.string,
  styleName: PT.string,
  children: PT.any,
  color: PT.string,
  flat: PT.bool,
  socialButton: PT.bool,
  style: PT.object
};

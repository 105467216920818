const Integrations = () => {
  const IntegrationData = {
    accessTypeKey: "DFQweb8twCUzouLF4EjuRjmU",
    accessTypeBkIntegrationId: 4,
    stagingAccessTypeKey: "76roknPdWLBsTUfWTW3NS5RS",
    stagingAccessTypeBkIntegrationId: 10,
    isAccesstypeStaging: false
  };
  return IntegrationData;
};

module.exports = Integrations;

/* eslint-disable react/prop-types */
import React from "react";
import "./player-progress-bar.m.css";

export function PlayerProgressBar({ value, handleSeekChange, max, barColor }) {
  return (
    <div styleName="seekbar">
      <input
        aria-label="Player progress time slider"
        type="range"
        value={value}
        min={0}
        max={max}
        onChange={e => handleSeekChange(e.target.value)}
      />
      <div
        styleName="seekbar-progress"
        style={barColor ? { backgroundColor: barColor } : {}}
      >
        <div
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax={max}
          aria-valuenow={value}
          style={{ width: (value / max) * 100 + "%" }}
        />
      </div>
    </div>
  );
}

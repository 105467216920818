/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useEffect } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavBar } from "./nav-bar";

import "./header.m.css";
// import { enableLogRocket } from "../helper/logger";
// import { checkLoggedInUser } from "../helper/api";
// import { parseUrl } from "query-string";

// Common wrapper for navigation. We could add OffcanvasMenu, Navbar etc components here.
const HeaderBase = props => {
  // const {
  //   publisher: { auth_host, accessTypeEnvironment },
  //   config: { domainSlug }
  // } = useSelector(({ qt }) => ({
  //   publisher: qt.config.publisher.publisher,
  //   config: qt.config
  // }));

  // const stagingParams = {
  //   callbackUrl: global.location && global.location.href,
  //   clientId: "76",
  //   redirectUrlEnglish:
  //     "https://newslaundry-web.qtstage.io/api/auth/v1/oauth/token",
  //   redirectUrlHindi:
  //     "https://newslaundry-hindi-web.qtstage.io/api/auth/v1/oauth/token"
  // };

  // const productionParams = {
  //   callbackUrl: global.location && global.location.href,
  //   clientId: "560",
  //   redirectUrlEnglish: "https://www.newslaundry.com/api/auth/v1/oauth/token",
  //   redirectUrlHindi: "https://hindi.newslaundry.com/api/auth/v1/oauth/token"
  // };

  // const member = useSelector(state => get(state, ["member"], null));

  useEffect(() => {
    // enableLogRocket();
    // const checkSSOAuth = async () => {
    //   const { query } = parseUrl(window.location.href);
    //   if (query && query.logged_in === "false") {
    //     const documentTitle = document.title;
    //     const urlSplit = window.location.href.split("?");
    //     const obj = { Title: documentTitle, Url: `${urlSplit[0]}` };
    //     window.history.pushState(obj, obj.Title, obj.Url);
    //     return;
    //   }
    //   try {
    //     const loggedInResponse = await checkLoggedInUser();
    //     if (loggedInResponse) {
    //       return;
    //     }
    //     window.location.href = `${auth_host}/api/auth/v1/oauth/auto-sso/authorize?client_id=${
    //       accessTypeEnvironment
    //         ? stagingParams.clientId
    //         : productionParams.clientId
    //     }&redirect_uri=${
    //       accessTypeEnvironment
    //         ? domainSlug
    //           ? stagingParams.redirectUrlHindi
    //           : stagingParams.redirectUrlEnglish
    //         : domainSlug
    //         ? productionParams.redirectUrlHindi
    //         : productionParams.redirectUrlEnglish
    //     }&callback_uri=${
    //       accessTypeEnvironment
    //         ? stagingParams.callbackUrl
    //         : productionParams.callbackUrl
    //     }&response_type=code`;
    //     // window.location.href = `${auth_host}/api/auth/v1/oauth/auto-sso/authorize?client_id=76&redirect_uri=https://newslaundry${
    //     //   domainSlug ? "-hindi" : ""
    //     // }-web.qtstage.io/api/auth/v1/oauth/token&callback_uri=https://newslaundry${
    //     //   domainSlug ? "-hindi" : ""
    //     // }-web.qtstage.io&response_type=code`;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };
    // checkSSOAuth();
  }, []);

  return (
    <div styleName="container">
      <a href="#container" styleName="skip-link">Skip to main content</a>
      <NavBar {...props} />
     </div>
  );
};

function mapStateToProps(state) {
  return {
    menu: get(state, ["qt", "data", "navigationMenu"], []),
    currentPath: get(state, ["qt", "currentPath"], "")
  };
}

HeaderBase.propTypes = {
  currentPath: PropTypes.string
};

export const Header = connect(mapStateToProps, () => ({}))(HeaderBase);

import _ from "lodash";
import { createShopifyOrder } from "../../../cartoon-mango";
import { campaignMerchandise } from "./campaign-checkout/campaign-merchandise";
import { subscriptionMerchandise } from "./confirmation/MERCH";

export const parseAddressData = userAddress => {
  if (userAddress) {
    const fields = userAddress.split(";");

    const state = fields[0].split(":");
    const district = fields[1].split(":");
    const phone = fields[2].split(":");
    const pin = fields[3].split(":");
    const addr = fields[4].split(":");

    return {
      address: addr[1],
      phone: phone[1],
      state: state[1],
      city: district[1],
      pincode: pin[1]
    };
  }
};

export const createMerchOrderAfterPayment = async (
  planName,
  subType,
  member,
  addressData
) => {
  try {
    const { address, phone, city, state, pincode } = addressData;

    let productsToOrder;

    if (subType === "campaign") {
      productsToOrder = campaignMerchandise[planName];
    } else if (subType === "subscription") {
      productsToOrder = subscriptionMerchandise[planName];
    }

    const lineItems =
      productsToOrder &&
      productsToOrder.length &&
      productsToOrder.map(product => ({
        variant_id: product.variantId,
        quantity: 1
      }));

    let userName = {};

    if (member["last-name"] && member["last-name"].length) {
      userName = {
        first_name: member["first-name"] || member.name,
        last_name: member["last-name"]
      };
    } else {
      userName = {
        first_name: "",
        last_name: member["first-name"] || member.name
      };
    }

    const userAddress = {
      address1: address,
      phone: phone,
      city,
      province: state,
      zip: pincode,
      country: "India"
    };

    const tags = ["website_order", "campaign_order", _.snakeCase(planName)];

    const payload = {
      order: {
        email: member.email,
        line_items: [...lineItems],
        customer: {
          first_name: member["first-name"] || member.name,
          last_name: member["last-name"] || "",
          email: member.email
        },
        billing_address: {
          ...userName,
          ...userAddress
        },
        shipping_address: {
          ...userName,
          ...userAddress
        },
        tags: tags.join(",")
      }
    };

    const order = await createShopifyOrder(payload);
    return order;
  } catch (error) {
    console.log("merch error", error);
  }
};

export const generateRedirectURL = () => global.location;

export const generateSuccessUrl = () => {
  const redirectUrl = generateRedirectURL();

  return `${redirectUrl}&payment_status=success&sena=true`;
};

export const saveDataToLocalStorage = (data, key) => {
  if (window.localStorage.getItem(key)) {
    window.localStorage.removeItem(key);
  }

  window.localStorage.setItem(key, data);
};

export const fetchDataFromLocalStorage = key => {
  if (key) {
    const data = window.localStorage.getItem(key);

    if (data) {
      return data;
    }
  }

  return null;
};

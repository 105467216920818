import {
  IS_OPEN_LOGIN_FORM,
  SELECTED_PLAN,
  PAYMENT_CHECKOUT_PAGE,
  FAVORATE_SONG,
  PODCAST_CLICK,
  IS_SUBSCRIPTION_CANCEL,
  SET_TRACK,
  SET_PLAYING,
  USER_SUBSCRIPTION,
  USER_SUBSCRIPTION_LOADING,
  SUBSCRIPTION_CTA,
  PAYMENT_SUCCESS,
  USER_SUBSCRIPTION_REQUESTED,
  USER_SUBSCRIPTION_RECEIVED,
  INTEGRATION_TOKEN,
  USER_LOGOUT_SUBSCRIPTION_CLEAR,
  USER_COUNTRY_REQUEST,
  USER_COUNTRY_SUCCESS,
  USER_COUNTRY_FAILURE
} from "./actions";

function loginReducer(state = false, action) {
  switch (action.type) {
    case IS_OPEN_LOGIN_FORM:
      return action.payload;
    default:
      return state;
  }
}

function selectedPlan(state = {}, action) {
  switch (action.type) {
    case SELECTED_PLAN:
      return action;
    default:
      return state;
  }
}

function paymentCheckout(state = {}, action) {
  switch (action.type) {
    case PAYMENT_CHECKOUT_PAGE:
      return action.payload;
    default:
      return state;
  }
}

function favorateSongPlaylist(state = {}, action) {
  switch (action.type) {
    case FAVORATE_SONG:
      return action.payload;
    default:
      return state;
  }
}

function hasSubscripton(state = {}, action) {
  switch (action.type) {
    case PODCAST_CLICK:
      return action.payload;
    default:
      return state;
  }
}

const initialPlayerState = {
  track: null,
  playing: false
};

function player(state = initialPlayerState, action) {
  switch (action.type) {
    case SET_TRACK:
      return { ...state, track: { ...action.payload.track } };
    case SET_PLAYING:
      return {
        ...state,
        playing: action.payload.playing,
        track: action.payload.track
      };
    default:
      return state;
  }
}

function amazonPollyReducer(state = { isOpen: false, storyData: {} }, action) {
  switch (action.type) {
    case "OPEN_AMAZON_POLLY":
      return { ...state, isOpen: true };
    case "CLOSE_AMAZON_POLLY":
      return { ...state, isOpen: false };
    case "UPDATE_AMAZON_POLLY_DATA":
      return { ...state, storyData: { ...action.payload.storyData } };
    default:
      return state;
  }
}

function isSubscriptionCancel(state = {}, action) {
  switch (action.type) {
    case IS_SUBSCRIPTION_CANCEL:
      return action.payload;
    default:
      return state;
  }
}

function isSubscribedUser(state = {}, action) {
  switch (action.type) {
    case USER_SUBSCRIPTION:
      return { ...state, ...action.payload };
    case USER_SUBSCRIPTION_LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
}

function showSubscriptionCTA(state = {}, action) {
  switch (action.type) {
    case SUBSCRIPTION_CTA:
      return action.payload;
    default:
      return state;
  }
}

function paymentSuccess(state = {}, action) {
  switch (action.type) {
    case PAYMENT_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function fetchSubscription(state = {}, action) {
  switch (action.type) {
    case USER_SUBSCRIPTION_REQUESTED:
      return { ...state, isLoading: true };
    case USER_SUBSCRIPTION_RECEIVED:
      return {
        ...state,
        isLoading: false,
        items: action.items,
        isActive: action.isActive,
        isExpired: action.isExpired,
        isRecurring: action.isRecurring
      };
    case USER_LOGOUT_SUBSCRIPTION_CLEAR:
      return {
        ...state,
        isLoading: false,
        items: [],
        isActive: false,
        isExpired: false,
        isRecurring: false
      };
    default:
      return state;
  }
}

function fetchUserCountry(state = {}, action) {
  switch (action.type) {
    case USER_COUNTRY_REQUEST:
      return { ...state, isLoading: true };
    case USER_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        country_code: action.country_code,
        country_name: action.country_name,
        country_code_alias: action.country_code_alias,
        user_timezone: action.user_timezone
      };
    case USER_COUNTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        country_code: action.country_code,
        country_name: action.country_name,
        country_code_alias: action.country_code_alias,
        user_timezone: action.user_timezone
      };
    default:
      return state;
  }
}

function fetchIntegrationID(state = {}, action) {
  switch (action.type) {
    case INTEGRATION_TOKEN:
      return { ...state, token: action.token };
    default:
      return state;
  }
}

export const REDUCERS = {
  isLoginOpen: loginReducer,
  selectedPlan,
  paymentCheckout,
  favorateSongPlaylist,
  hasSubscripton,
  amazonPolly: amazonPollyReducer,
  isSubscriptionCancel,
  player,
  isSubscribedUser,
  showSubscriptionCTA,
  paymentSuccess,
  fetchSubscription,
  fetchIntegrationID,
  fetchUserCountry
};

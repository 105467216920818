import React from "react";
import PT from "prop-types";

export function YoutubeIcon({ width = 17, height = 12, color = "#fff" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 12">
      <path
        fill={color}
        fillRule="nonzero"
        d="M16.337 1.03C15.724.298 14.591 0 12.427 0H4.573C2.36 0 1.207.318.596 1.097 0 1.857 0 2.975 0 4.524v2.952C0 10.476.707 12 4.573 12h7.854c1.876 0 2.916-.263 3.589-.91.69-.661.984-1.742.984-3.614V4.524c0-1.633-.046-2.759-.663-3.494zm-5.423 5.378l-3.567 1.87a.547.547 0 0 1-.802-.488V4.062a.55.55 0 0 1 .801-.488l3.567 1.858a.55.55 0 0 1 .001.976z"
      />
    </svg>
  );
}

YoutubeIcon.propTypes = {
  color: PT.string,
  width: PT.number,
  height: PT.number
};

/* eslint-disable react/prop-types */
import React, { useState } from "react";

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

import _ from "lodash";

/**
 * Radio group component
 */
const RadioGroup = React.forwardRef(
  (
    { children, orientation = "vertical", defaultValue, ...props },
    forwardedRef
  ) => {
    const allClassNames = `radio-group-root ${props.className || ""} ${
      props.styleName || ""
    }`;

    return (
      <RadioGroupPrimitive.Root
        ref={forwardedRef}
        defaultValue={defaultValue}
        orientation={orientation}
        aria-orientation={orientation}
        {...props}
        className={allClassNames}
      >
        {children}
      </RadioGroupPrimitive.Root>
    );
  }
);
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef(
  ({ className, children, ...props }, forwardedRef) => {
    const [itemId] = useState(() => _.uniqueId("newsable-radio-group-"));
    const [labelId] = useState(() => _.uniqueId("newsable-radio-group-"));

    const allClassNames = `radio-group-item ${props.className || ""} ${
      props.styleName || ""
    }`;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "var(--space-xs)"
        }}
      >
        <RadioGroupPrimitive.Item
          ref={forwardedRef}
          id={itemId}
          aria-labelledby={labelId}
          {...props}
          className={allClassNames}
        >
          <RadioGroupIndicator />
        </RadioGroupPrimitive.Item>
        <label id={labelId} htmlFor={itemId}>
          {children}
        </label>
      </div>
    );
  }
);
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

const RadioGroupIndicator = React.forwardRef(
  ({ className, ...props }, forwardedRef) => {
    const allClassNames = `radio-group-indicator ${props.className || ""} ${
      props.styleName || ""
    }`;

    return (
      <RadioGroupPrimitive.Indicator
        ref={forwardedRef}
        {...props}
        className={allClassNames}
      />
    );
  }
);
RadioGroupIndicator.displayName = RadioGroupPrimitive.Indicator.displayName;

const RadioGroupCompoundComponent = Object.assign({}, RadioGroup, {
  Item: RadioGroupItem,
  Indicator: RadioGroupIndicator
});

export { RadioGroupCompoundComponent as RadioGroup };

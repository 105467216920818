import React from "react";
import "./loader.m.css";

export function Loader({ fill }) {
  return (
    <span styleName="loading">
      <b></b>
      <b></b>
      <b></b>
    </span>
  );
}

export const subscriptionMerchandise = {
  Soldier: [
    {
      productId: 7295788187814,
      variantId: 42310157598886,
      name: "NL Sena Mug",
      subscriptionPrice: 1000
    }
  ],
  Defender: [
    {
      productId: 7295788187814,
      variantId: 42310157598886,
      name: "NL Sena Mug",
      subscriptionPrice: 1000
    }
  ],
  Bishop: [
    {
      productId: 7295788187814,
      variantId: 42310157598886,
      name: "NL Sena Mug",
      subscriptionPrice: 2500
    }
  ],
  "Truth-seeker": [
    {
      productId: 7295788187814,
      variantId: 42310157598886,
      name: "NL Sena Mug",
      subscriptionPrice: 2500
    }
  ],
  Knight: [
    {
      productId: 7295788187814,
      variantId: 42310157598886,
      name: "NL Sena Mug",
      subscriptionPrice: 10000
    },
    {
      productId: 5935233958054,
      variantId: 37020473852070,
      name: "Sabki Dhulai Soap Set",
      subscriptionPrice: 10000
    }
  ],
  Patron: [
    {
      productId: 7295788187814,
      variantId: 42310157598886,
      name: "NL Sena Mug",
      subscriptionPrice: 10000
    },
    {
      productId: 5935233958054,
      variantId: 37020473852070,
      name: "Sabki Dhulai Soap Set",
      subscriptionPrice: 10000
    }
  ],
  Liberator: [
    {
      productId: 7295788187814,
      variantId: 42310157598886,
      name: "NL Sena Mug",
      subscriptionPrice: 5000
    },
    {
      productId: 5935233958054,
      variantId: 37020473852070,
      name: "Sabki Dhulai Soap Set",
      subscriptionPrice: 5000
    }
  ],
  Rook: [
    {
      productId: 5935254372518,
      variantId: 37020568977574,
      name: "Tote Bag",
      subscriptionPrice: 20000
    }
  ],
  "King/Queen": [
    {
      productId: 5935242379430,
      variantId: 37020546990246,
      name: "Newslaundry Sipper",
      subscriptionPrice: 30000
    }
  ],
  Grandmaster: [
    {
      productId: 7295788187814,
      variantId: 42310157598886,
      name: "NL Sena Mug",
      subscriptionPrice: 50000
    },
    {
      productId: 5935233958054,
      variantId: 37020473852070,
      name: "Sabki Dhulai Soap Set",
      subscriptionPrice: 50000
    },
    {
      productId: 5935254372518,
      variantId: 37020568977574,
      name: "Tote Bag",
      subscriptionPrice: 50000
    },
    {
      productId: 5935242379430,
      variantId: 37020546990246,
      name: "Newslaundry Sipper",
      subscriptionPrice: 50000
    },
    {
      productId: 6960895197350,
      variantId: 40915655393446,
      name: "Car Stickers Set",
      subscriptionPrice: 50000
    },
    {
      productId: 7150479409318,
      variantId: 41697566687398,
      name: "Fridge Magnets",
      subscriptionPrice: 50000
    },
    {
      productId: 6938100957350,
      variantId: 40813153943718,
      name: "Laptop Sticker Set",
      subscriptionPrice: 50000
    },
    {
      productId: 5935250178214,
      variantId: 37020561932454,
      name: "T-shirt Medium",
      subscriptionPrice: 50000
    },
    {
      productId: 5935250178214,
      variantId: 37020561965222,
      name: "T-shirt Large",
      subscriptionPrice: 50000
    },
    {
      productId: 5935250178214,
      variantId: 37020561997990,
      name: "T-shirt Extra Large",
      subscriptionPrice: 50000
    }
  ],
  "Independent News Guardian": [
    {
      productId: 5935254372518,
      variantId: 37020568977574,
      name: "Tote Bag",
      subscriptionPrice: 20000
    }
  ],
  gameChanger: [
    {
      productId: 5935254372518,
      variantId: 37020568977574,
      name: "Tote Bag",
      subscriptionPrice: 10000
    },
    {
      productId: 6960895197350,
      variantId: 40915655393446,
      name: "Car Stickers Set",
      subscriptionPrice: 50000
    },
    {
      productId: 7150479409318,
      variantId: 41697566687398,
      name: "Fridge Magnets",
      subscriptionPrice: 50000
    },
    {
      productId: 6938100957350,
      variantId: 40813153943718,
      name: "Laptop Sticker Set",
      subscriptionPrice: 50000
    }
  ],
  disruptor: [
    {
      productId: 7150479409318,
      variantId: 41697566687398,
      name: "Fridge Magnets",
      subscriptionPrice: 50000
    },
    {
      productId: 6938100957350,
      variantId: 40813153943718,
      name: "Laptop Sticker Set",
      subscriptionPrice: 50000
    }
  ]
};

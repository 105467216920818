import { pickComponentHelper } from "@quintype/framework/server/pick-component-helper";
import { PAGE_TYPE } from "./constants";

const { pickComponent, getChunkName } = pickComponentHelper(
  {
    [PAGE_TYPE.HOME_PAGE]: { chunk: "list", component: "HomePage" },
    [PAGE_TYPE.SECTION_PAGE]: { chunk: "list", component: "SectionPage" },
    [PAGE_TYPE.COLLECTION_PAGE]: { chunk: "list", component: "SectionPage" },
    [PAGE_TYPE.TAG_PAGE]: { chunk: "list", component: "TagPage" },
    [PAGE_TYPE.SEARCH_PAGE]: { chunk: "list", component: "SearchPage" },
    [PAGE_TYPE.STORY_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.CATALOG_PAGE]: { chunk: "list", component: "CatalogPage" },
    [PAGE_TYPE.STORY_PREVIEW]: {
      chunk: "story",
      component: "StoryPagePreview"
    },
    [PAGE_TYPE.STORY_PUBLIC_PREVIEW_PAGE]: {
      chunk: "story",
      component: "StoryPage"
    },
    [PAGE_TYPE.HOME_PREVIEW]: { chunk: "list", component: "HomePagePreview" },
    [PAGE_TYPE.AUTHOR_PAGE]: { chunk: "list", component: "AuthorPage" },
    [PAGE_TYPE.COMPANY_PAGE]: { chunk: "list", component: "CompanyPage" },
    [PAGE_TYPE.SUBSCRIPTION_PAGE]: {
      chunk: "list",
      component: "SubscriptionPage"
    },
    [PAGE_TYPE.SUBSCRIPTION_CHECKOUT]: {
      chunk: "list",
      component: "SubscriptionCheckout"
    },
    [PAGE_TYPE.CAMPAIGN_SUBSCRIPTION_CHECKOUT]: {
      chunk: "list",
      component: "CampaignCheckout"
    },
    [PAGE_TYPE.GIFT_SUBSCRIPTION]: {
      chunk: "list",
      component: "GiftSubscription"
    },
    [PAGE_TYPE.TRIAL_SUBSCRIPTION_PAGE]: {
      chunk: "list",
      component: "TrialSubscription"
    },
    [PAGE_TYPE.INDEPENDENCE_DAY_OFFER_PAGE]: {
      chunk: "list",
      component: "IndependenceDayPage"
    },
    [PAGE_TYPE.NEWSLETTER_PAGE]: { chunk: "list", component: "NewsletterPage" },
    [PAGE_TYPE.PAYMENT_CHECKOUT_PAGE]: {
      chunk: "list",
      component: "PaymentCheckoutPage"
    },
    [PAGE_TYPE.ACCOUNT_DETAILS_PAGE]: {
      chunk: "list",
      component: "AccountDetails"
    },
    [PAGE_TYPE.STUDENT_SUBSCRIPTION_PAGE]: {
      chunk: "list",
      component: "StudentSubscription"
    },
    [PAGE_TYPE.SPONSOR_STUDENT_SUBSCRIPTION_PAGE]: {
      chunk: "list",
      component: "SponsorStudentSubscriptionPage"
    },
    [PAGE_TYPE.DIWALI_MERCH]: { chunk: "list", component: "DiwaliMerchPage" },
    [PAGE_TYPE.SAVED_PAGE]: { chunk: "list", component: "Saved" },
    [PAGE_TYPE.CHRISTMAS_OFFER]: {
      chunk: "list",
      component: "ChristmasOfferPage"
    },
    [PAGE_TYPE.VALENTINE_PAGE]: {
      chunk: "list",
      component: "ValentinePage"
    },
    [PAGE_TYPE.SETTINGS_PAGE]: {
      chunk: "list",
      component: "SettingsPage"
    },
    [PAGE_TYPE.MERCH_PAGE]: { chunk: "list", component: "MerchPage" },
    [PAGE_TYPE.PODCAST_RSS]: { chunk: "list", component: "PodcastRssPage" },
    [PAGE_TYPE.PODCAST_LETTERS]: { chunk: "list", component: "PodcastLetters" },
    [PAGE_TYPE.LOGIN_PAGE]: { chunk: "list", component: "LoginPage" },
    [PAGE_TYPE.FAQ_PAGE]: { chunk: "list", component: "FaqPage" },
    [PAGE_TYPE.ABOUT_US_PAGE]: { chunk: "list", component: "AboutUsPage" },
    [PAGE_TYPE.TEAM_PAGE]: { chunk: "list", component: "TeamPage" },
    [PAGE_TYPE.FELLOWSHIP_PAGE]: { chunk: "list", component: "Fellowship" },
    [PAGE_TYPE.CONTACT_US_PAGE]: { chunk: "list", component: "ContactUsPage" },
    [PAGE_TYPE.FRIENDS_OF_NL]: { chunk: "list", component: "FriendsOfNLPage" },
    [PAGE_TYPE.STUDENT_SUBSCRIPTION_EXPLAINED_PAGE]: {
      chunk: "list",
      component: "StudentSubscriptionExplainedPage"
    },
    [PAGE_TYPE.ART_AUCTION_PAGE]: {
      chunk: "list",
      component: "ArtAuctionPage"
    },
    [PAGE_TYPE.GRIEVANCE_REDRESSAL]: {
      chunk: "list",
      component: "GrievanceRedressal"
    },
    [PAGE_TYPE.DOWNLOAD_APP]: {
      chunk: "list",
      component: "DownloadApp"
    },
    [PAGE_TYPE.GROUP_SUBSCRIPTION]: {
      chunk: "list",
      component: "GroupSubscription"
    },
    [PAGE_TYPE.SPECTRUM_PAGE]: {
      chunk: "list",
      component: "Spectrum"
    },
    [PAGE_TYPE.VOTING_PAGE]: {
      chunk: "list",
      component: "Voting"
    },
    [PAGE_TYPE.SPECTRUM_RESULTS_PAGE]: {
      chunk: "list",
      component: "SpectrumResults"
    },
    [PAGE_TYPE.HINDI_WHATSAPP_OPTIN]: {
      chunk: "list",
      component: "HindiWhatsappOptin"
    },
    [PAGE_TYPE.ANGEL_SUBSCRIBERS]: {
      chunk: "list",
      component: "AngelSubscriber"
    },
    [PAGE_TYPE.COMBINED_SENA]: {
      chunk: "list",
      component: "combinedSena"
    },
    [PAGE_TYPE["2024_ELECTION_FUND"]]: {
      chunk: "list",
      component: "ElectionFund2024"
    },
    [PAGE_TYPE.RESULTSDAY_LIVE]: {
      chunk: "list",
      component: "resultsDayLive"
    },
    [PAGE_TYPE.PHOTO_GALLERY]: {
      chunk: "list",
      component: "PhotoGallery"
    },
    default: { chunk: "list", component: "NotFoundPage" }
  },
  {
    list: () =>
      import(/* webpackChunkName: "list" */ "./component-bundles/list.js"),
    story: () =>
      import(/* webpackChunkName: "story" */ "./component-bundles/story.js")
  }
);

export { getChunkName, pickComponent };

/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { getAcastEpisode } from "../helper/api";
import { CloseIcon } from "./icons";
import { Loader } from "./Loader";
import { PlayerContext } from "./player-context";
import { hmsToSecondsOnly, timestampify } from "./utils";

import "./show-notes.m.css";
import { PlayerModal } from "./player-modal";

function fetcher({ queryKey }) {
  return getAcastEpisode(queryKey[1].showId, queryKey[1].episodeId);
}

export function ShowNotes({ currentTrack }) {
  // console.log("CURRENT TRACK", currentTrack);

  const playerContext = useContext(PlayerContext);

  const { data, status, error, refetch } = useQuery(
    [
      "episode",
      {
        episodeId: currentTrack._id || currentTrack.episode_id,
        showId: currentTrack.show || currentTrack.showId
      }
    ],
    fetcher
  );

  const onAfterOpen = () => {
    refetch();
  };

  return (
    <PlayerModal
      show={playerContext.showNotes}
      handleClose={playerContext.toggleShowNotes}
      onAfterOpen={onAfterOpen}
      label="Episode Notes"
    >
      <section styleName="show_notes_wrapper">
        <article styleName="show_notes">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "0.5rem",
              position: "sticky",
              top: 0
            }}
          >
            <button
              title="Close"
              onClick={playerContext.toggleShowNotes}
              className="pressable"
            >
              <CloseIcon width={18} />
            </button>
          </div>
          <header>Episode Notes</header>
          {status === "loading" ? <Loader /> : null}
          <Content data={data} />
          {status === "error" ? <p>Error: {error.message}</p> : null}
        </article>
      </section>
    </PlayerModal>
  );
}

const Content = ({ data }) => {
  const playerContext = useContext(PlayerContext);
  const htmlWrapperRef = useRef(null);

  useEffect(() => {
    if (data) {
      const htmlString = data.episode.summary || "Notes not available.";
      const res = timestampify(htmlString);
      htmlWrapperRef.current.innerHTML = res;
      // console.log({ res });
      const btns = document.querySelectorAll("button.timestamp-link");

      btns.forEach(btn => {
        btn.addEventListener("click", event => {
          const time = event.target.dataset.time;
          const timeInSec = hmsToSecondsOnly(time);
          playerContext.seek(timeInSec);
          playerContext.toggleShowNotes();
        });
      });
    }
  }, [data]);

  return <div ref={htmlWrapperRef} />;
};

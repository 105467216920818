/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { PlayerContext } from "./player-context";
import { convertHMS } from "../../utils";
import { PlayerProgressBar } from "./player-progress-bar";
import { PlaySpeedSelect } from "./play-speed-select";
import { MovingText } from "./moving-text";
import { Loader } from "./Loader";

import {
  Forward,
  LibraryIcon,
  PauseButton,
  PlayButton,
  Rewind,
  ShowNotes
} from "./icons";
import "./desktop-bottom-player.m.css";
import { Label } from "@nl-rishabh/awesome-components-tsup";

export function DesktopBottomPlayer({ handleClose }) {
  const playerState = useContext(PlayerContext);

  const { currentlyPlaying } = playerState;
  // console.log({ currentlyPlaying, podcastHistory });

  const { member, fetchSubscription } = useSelector(state => state);

  const pushToDataLayer = (event, timeStamp) => {
    const obj = {
      event: event,
      podcast_name: currentlyPlaying.title,
      podcast_creator: currentlyPlaying.showTitle,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    if (timeStamp) {
      obj.podcast_completion = Math.ceil(timeStamp);
      obj.max_podcast_duration = Math.ceil(Number(currentlyPlaying.duration));
    }

    window.dataLayer.push(obj);
  };

  const renderPlayButton = () => {
    const status = playerState.audioStatus;
    if (status === "playing") {
      return (
        <button
          aria-label="Pause"
          title="Pause"
          onClick={() => {
            pushToDataLayer("podcast_pause", playerState.currentTime);
            playerState.pause("podcast_pause", playerState.currentTime);
          }}
          className="pressable"
          styleName="player-control play-button"
        >
          <PauseButton width="24" />
        </button>
      );
    } else if (status === "loading") {
      return (
        <button
          aria-label="loading"
          aria-disabled={true}
          title="loading"
          className="pressable"
          styleName="player-control play-button"
        >
          <Loader />
        </button>
      );
    }

    return (
      <button
        aria-label="Play"
        title="Play"
        onClick={() => {
          pushToDataLayer("podcast_play", playerState.currentTime);
          playerState.play();
        }}
        className="pressable"
        styleName="player-control play-button"
      >
        <PlayButton width="48px" height="48px" fill="#fff" />
      </button>
    );
  };

  // console.log({ playerState });

  return (
    <div styleName="bottom_player_desktop">
      <PlayerProgressBar
        barColor="#5a5757"
        max={Number(currentlyPlaying.duration)}
        value={playerState.currentTime}
        handleSeekChange={time => playerState.seek(time)}
      />

      <div styleName="bottom_player_row">
        <div
          style={{
            alignSelf: "flex-start",
            fontSize: "1rem",
            textAlign: "center",
            minWidth: "4rem"
          }}
          aria-label={`Elapsed time:`}
        >
          {convertHMS(playerState.currentTime)}
        </div>
        <div styleName="bottom_player_desktop_artwork_wrapper">
          <div styleName="bottom_player_desktop_artwork" >
            <img src={currentlyPlaying.cover} alt="Podcast episode's artwork" aria-label={`Artwork of currently playing episode titled: ${currentlyPlaying.title}`}/>
          </div>
          <div styleName="bottom_player_desktop_meta">
            <div styleName="bottom_player_desktop_meta--title" aria-hidden="true">
              <MovingText>{currentlyPlaying.title}</MovingText>
            </div>
            <div style={{ fontSize: "0.9rem" }} >
              {currentlyPlaying.showTitle}
            </div>
          </div>
        </div>
        <div styleName="bottom_player_desktop_controls">
          <button
            title="Rewind"
            aria-label="Rewind by 15 seconds"
            onClick={() => playerState.seek(playerState.currentTime - 15)}
            styleName="player-control"
            className="pressable"
          >
            <Rewind width={32} />
          </button>
          {renderPlayButton()}
          <button
            title="Skip"
            aria-label="Forward by 30 seconds"
            onClick={() => playerState.seek(playerState.currentTime + 30)}
            styleName="player-control"
            className="pressable"
          >
            <Forward width={32} />
          </button>
        </div>

        <div styleName="bottom_player_desktop_btns">
          <div styleName="player-control" className="pressable">
            <PlaySpeedSelect
              value={playerState.playbackRate}
              handleChange={value => playerState.changePlaybackSpeed(value)}
            />
          </div>
          <button
            title="Episode notes"
            aria-label="Open Episode notes"
            className="pressable"
            styleName="player-control"
            onClick={playerState.toggleShowNotes}
          >
            <ShowNotes width={28} />
          </button>
          <button
            title="Shows"
            aria-label="Open Show list"
            className="pressable"
            styleName="player-control"
            onClick={() => playerState.setCurrentScreen("library")}
          >
            <LibraryIcon width={26} />
          </button>
          {/* <button
            title="Queue"
            aria-label="Open your track queue"
            className="pressable"
            styleName="player-control"
            onClick={() => playerState.setCurrentScreen("queue")}
          >
            <QueueIcon width={34} />
          </button> */}
          <button
            aria-label="Stop player"
            title="Close"
            styleName="player-control"
            className="pressable"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
        <div
          style={{
            alignSelf: "flex-start",
            fontSize: "1rem",
            textAlign: "center",
            minWidth: "4rem"
          }}
          aria-label={`Total episode duration:`}
        >
          {convertHMS(Number(currentlyPlaying.duration))}
        </div>
      </div>
    </div>
  );
}

function CloseIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5922 30.2561L23.2922 17.9157L35.6203 5.73451C36.1266 5.22853 36.1266 4.40396 35.6203 3.89797L32.1141 0.374805C31.8703 0.131182 31.5422 0 31.1953 0C30.8484 0 30.5203 0.140552 30.2766 0.374805L18.0047 12.5185L5.71406 0.384175C5.47031 0.140552 5.14219 0.00937069 4.79531 0.00937069C4.44844 0.00937069 4.12031 0.149922 3.87656 0.384175L0.379688 3.90734C-0.126562 4.41333 -0.126562 5.2379 0.379688 5.74388L12.7078 17.925L0.417188 30.2561C0.173438 30.4997 0.0328128 30.8277 0.0328128 31.1744C0.0328128 31.5211 0.164063 31.849 0.417188 32.0927L3.92344 35.6158C4.17656 35.8688 4.50469 36 4.84219 36C5.17031 36 5.50781 35.8782 5.76094 35.6158L18.0047 23.3222L30.2578 35.6065C30.5109 35.8594 30.8391 35.9906 31.1766 35.9906C31.5047 35.9906 31.8422 35.8688 32.0953 35.6065L35.6016 32.0833C35.8453 31.8397 35.9859 31.5117 35.9859 31.165C35.9766 30.8277 35.8359 30.4997 35.5922 30.2561Z"
        fill="#E3E2E2"
      />
    </svg>
  );
}

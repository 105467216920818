import React from "react";
import PropTypes from "prop-types";

import * as VisuallyHiddenPrimitive from "@radix-ui/react-visually-hidden";

import { cva } from "class-variance-authority";
import { Loader2 as Spinner } from "lucide-react";

// import "./Button.m.css";

export const buttonVariants = cva("button__base", {
  variants: {
    variant: {
      neutral: "button__variant__neutral",
      accent: "button__variant__accent",
      danger: "button__variant__danger",
      attention: "button__variant__attention",
      success: "button__variant__success",
      outline: "",
      ghost: ""
    },
    size: {
      sm: "",
      base: "component-padding-base typography-font-base",
      lg: ""
    }
  },
  defaultVariants: {
    variant: "neutral",
    size: "base"
  }
});

/**
 * Button Component
 *
 * Follows Button a11y pattern
 * @see https://www.w3.org/WAI/ARIA/apg/patterns/button/
 */
const Button = React.forwardRef(
  (
    { variant, size, children, disabled, isLoading, ...props },
    forwardedRef
  ) => {
    const shouldBeDisabled = disabled || isLoading;

    const allClassNames = `${buttonVariants({ variant, size })} ${
      props.className || ""
    } ${props.styleName || ""}`;

    return (
      <button
        ref={forwardedRef}
        disabled={shouldBeDisabled}
        data-loading={isLoading ? "true" : undefined}
        aria-disabled={shouldBeDisabled}
        {...props}
        className={allClassNames}
      >
        {isLoading ? (
          <>
            <Spinner
              style={{ height: "1rem", width: "1rem", flexShrink: 0 }}
              className="animate-spin"
              aria-hidden="true"
            />
            <VisuallyHiddenPrimitive.Root>
              Loading...
            </VisuallyHiddenPrimitive.Root>
          </>
        ) : (
          children
        )}
      </button>
    );
  }
);
Button.displayName = "Button";

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  styleName: PropTypes.string
};

export { Button };

/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { EpisodeList, ShowList } from "./show-list";

import "./show-list.m.css";
import { PlayerModal } from "./player-modal";

export function PodcastPlayer({
  handleClose,
  currentScreen,
  currentShow,
  setCurrentShow
}) {
  const [podcast, setPodcast] = useState(null);

  return (
    <PlayerModal
      label={podcast ? podcast.title + " Episodes" : "Podcast library"}
      show={currentScreen === "library"}
      handleClose={handleClose}
    >
      {podcast ? (
        <EpisodeList
          podcast={podcast}
          handleBack={() => {
            setCurrentShow(null);
            setPodcast(null);
          }}
          handleClose={handleClose}
        />
      ) : (
        <ShowList
          handleClick={podcast => setPodcast(podcast)}
          handleClose={handleClose}
          currentShow={currentShow}
        />
      )}
    </PlayerModal>
  );
}

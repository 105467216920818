/* eslint-disable react/prop-types */
import { get } from "lodash";
import React, { Component, createContext } from "react";
import { connect } from "react-redux";
import {
  createUserPodcastHistory,
  fetchUserPodcastHistory,
  getIntegrationToken
} from "../helper/api";
import { BottomPlayer } from "./bottom-player";
import { PodcastPlayer } from "./podcast-player";
// import { Queue } from "./queue";
import { ShowNotes } from "./show-notes";
import { setGlobalPlayerRef } from "./utils";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  fetchDataFromLocalStorage,
  saveDataToLocalStorage
} from "../pages/subscription-checkout/utils";

const initialState = {
  queue: [],
  playbackRate: 1,
  currentTrackId: null,
  audioStatus: null,
  currentTime: 0,
  currentScreen: null,
  showPlayer: false,
  currentShow: null,
  loadingUserQueue: false,
  showPlayerFullScreen: false,
  showNotes: false,
  showMenu: false,

  podcastHistory: [],
  currentlyPlaying: null,
  isDownloaded: false,
  userToken: null
};

const PlayerContext = createContext(initialState);

let PlayerContextProvider = class PlayerContextProvider extends Component {
  constructor(props) {
    super(props);
    setGlobalPlayerRef(this);
    this.state = initialState;
    this.listeners = [];
  }

  canPlayHandler = () => {
    // Refer - https://stackoverflow.com/questions/39437369/html5-audio-changing-currenttime-fires-multiple-canplay-events

    if (this.audio.paused) {
      this.setState({ audioStatus: "paused" });
    } else {
      this.setState({ audioStatus: "playing" });
    }
  };

  attachListeners() {
    const audio = new window.Audio();
    this.audio = audio;
    window.audio = audio;
    this.audio.onpause = () => {
      this.setState({ audioStatus: "paused" });
    };

    this.audio.onplay = () => {
      this.setState({ audioStatus: "playing" });
    };

    this.audio.onplaying = () => {
      this.setState({ audioStatus: "playing" });
    };

    this.audio.onratechange = () => {
      this.setState({ playbackRate: audio.playbackRate }, this.syncUserState);
    };

    this.audio.oncanplaythrough = () => {
      this.canPlayHandler();
    };

    this.audio.oncanplay = () => {
      this.canPlayHandler();
    };

    this.savingPlayerState = false;

    this.audio.ontimeupdate = () => {
      this.setState({ currentTime: audio.currentTime });

      if (!this.savingPlayerState) {
        this.savingPlayerState = true;
        // Save User state in every 10 seconds
        setTimeout(() => {
          this.syncUserState();
          this.savingPlayerState = false;
        }, 10000);
      }
    };

    this.audio.onended = () => {
      if (this.state.queue.length > 1) {
        this.playNextFromQueue();
      }
    };

    this.audio.onloadstart = () => {
      // iOS calls loadStart on initial load but never calls canPlay, so player stays in loading state. iOS needs user interaction to play audio, so never set loading state on initial load in iOS
      if (this.initialLoad) {
        this.initialLoad = false;
        return;
      }

      this.setState({ audioStatus: "loading" });
    };

    this.audio.onseeking = () => {
      this.setState({ audioStatus: "loading" });
    };

    this.audio.onseeked = () => {
      if (audio.paused) {
        this.setState({ audioStatus: "paused" });
      } else {
        this.setState({ audioStatus: "playing" });
      }
    };
  }

  restorePersistedState = async () => {
    // console.log("RESTORE PERSISTED STATE");

    this.setState({
      loadingUserQueue: true
    });

    try {
      const { podcastHistory } = await fetchUserPodcastHistory(
        this.state.userToken
      );

      this.setState({ podcastHistory });

      const currentTrackId = fetchDataFromLocalStorage("currentTrackId");
      const userEmail = fetchDataFromLocalStorage("userEmail");
      const showPlayerTray = fetchDataFromLocalStorage("showPlayerTray");

      this.setState({ showPlayer: !(showPlayerTray === "false") });

      const isLoggedInAndSameUser =
        this.props.member && userEmail && this.props.member.email === userEmail;

      // console.log({
      //   showPlayerTray,
      //   currentTrackId,
      //   userEmail,
      //   member: this.props.member,
      //   isLoggedInAndSameUser
      // });

      // bakchodi bc
      if (isLoggedInAndSameUser && currentTrackId) {
        const lastPlayedPodcast = podcastHistory.find(
          item => item.episode_id === currentTrackId
        );

        if (lastPlayedPodcast) {
          this.setState({
            currentlyPlaying: lastPlayedPodcast,
            currentTrackId: lastPlayedPodcast.episode_id,
            currentShow: lastPlayedPodcast.showId,
            isDownloaded: !(lastPlayedPodcast.is_downloaded === "false"),
            currentTime: Number(lastPlayedPodcast.duration_played)
          });

          this.initialLoad = true;
          this.userStateRestored = true;
          const startTime = Number(lastPlayedPodcast.duration_played) || 0;

          this.audio.src = lastPlayedPodcast.audio;
          if (startTime > 0) {
            this.audio.src += "#t=" + startTime;
          }
          this.audio.title = lastPlayedPodcast.title;
          this.audio.defaultPlaybackRate = 1;
          this.audio.playbackRate = 1;
        } else {
          const newestPodcast = podcastHistory[0];

          this.setState({
            currentlyPlaying: newestPodcast || null,
            currentTrackId: newestPodcast.episode_id || null,
            currentShow: newestPodcast.showId || null,
            isDownloaded: !(newestPodcast.is_downloaded === "false") || false,
            currentTime: Number(newestPodcast.duration_played) || 0
          });
        }
      } else {
        saveDataToLocalStorage(this.props.member.email, "userEmail");

        this.setState({
          currentlyPlaying: null,
          currentTrackId: null,
          currentShow: null,
          isDownloaded: false,
          currentTime: 0
        });

        saveDataToLocalStorage("", "currentTrackId");
      }

      if (this.userClickedMicIcon) {
        // Respect user preference above or else fallback to default behaviour
        this.togglePlayer();
        this.userStateRestored = true;
        this.userClickedMicIcon = false;
      } else {
        this.userStateRestored = true;
      }
    } catch (e) {
      console.log("Error while loading user player state ", e);
    } finally {
      this.setState({
        loadingUserQueue: false,
        currentlyPlaying: null,
        currentTrackId: null,
        currentShow: null,
        isDownloaded: false,
        currentTime: 0,
        showPlayer: false
      });
    }
  };

  syncUserState = async () => {
    const { currentTrackId, currentTime, isDownloaded } = this.state;

    // console.log(
    //   "SYNC USER STATE CALLED WITH",
    //   currentTrackId,
    //   currentTime,
    //   isDownloaded
    // );

    await createUserPodcastHistory(
      currentTrackId,
      currentTime,
      isDownloaded,
      this.state.userToken
    );

    if (this.props.member) {
      saveDataToLocalStorage(this.props.member.email, "userEmail");
    } else {
      saveDataToLocalStorage("", "userEmail");
    }

    // console.log("Updated Podcast", createdPodcast);
  };

  getSubscriptionState() {
    return this.state.userSubscriptions;
  }

  async componentDidMount() {
    // console.log("MOUNTED");

    this.attachListeners();

    const token = await getIntegrationToken();
    this.setState({ userToken: token });

    // Fetch player tray, current track ID and user email from localstorage if it exists
    const showPlayerTray = fetchDataFromLocalStorage("showPlayerTray");
    const currentTrackId = fetchDataFromLocalStorage("currentTrackId");
    const userEmail = fetchDataFromLocalStorage("userEmail");

    // Fetch user podcast history from CM DB
    const { podcastHistory } = await fetchUserPodcastHistory(token);
    this.setState({ podcastHistory });

    const isLoggedInAndSameUser =
      this.props.member && userEmail && this.props.member.email === userEmail;

    // console.log(
    //   "CHECK",
    //   this.props.member && userEmail && this.props.member.email === userEmail
    // );

    // console.log({
    //   showPlayerTray,
    //   currentTrackId,
    //   userEmail,
    //   member: this.props.member,
    //   isLoggedInAndSameUser
    // });

    // har jagah bakchodi bc
    if (currentTrackId && isLoggedInAndSameUser) {
      const lastPlayedPodcast = podcastHistory.find(
        item => item.episode_id === currentTrackId
      );

      if (lastPlayedPodcast) {
        this.setState({
          currentlyPlaying: lastPlayedPodcast,
          currentTrackId: lastPlayedPodcast.episode_id,
          currentShow: lastPlayedPodcast.showId,
          isDownloaded: !(lastPlayedPodcast.is_downloaded === "false"),
          currentTime: Number(lastPlayedPodcast.duration_played),
          showPlayer: true
        });

        this.initialLoad = true;
        this.userStateRestored = true;
        const startTime = Number(lastPlayedPodcast.duration_played) || 0;

        this.audio.src = lastPlayedPodcast.audio;
        if (startTime > 0) {
          this.audio.src += "#t=" + startTime;
        }
        this.audio.title = lastPlayedPodcast.title;
        this.audio.defaultPlaybackRate = 1;
        this.audio.playbackRate = 1;
      } else {
        const newestPodcast = podcastHistory[0];

        this.setState({
          currentlyPlaying: newestPodcast || null,
          currentTrackId: newestPodcast.episode_id || null,
          currentShow: newestPodcast.showId || null,
          isDownloaded: !(newestPodcast.is_downloaded === "false") || false,
          currentTime: Number(newestPodcast.duration_played) || 0,
          showPlayer: !!newestPodcast
        });

        if (newestPodcast) {
          this.initialLoad = true;
          this.userStateRestored = true;
          const startTime = Number(newestPodcast.duration_played) || 0;

          this.audio.src = newestPodcast.audio;
          if (startTime > 0) {
            this.audio.src += "#t=" + startTime;
          }
          this.audio.title = newestPodcast.title;
          this.audio.defaultPlaybackRate = 1;
          this.audio.playbackRate = 1;
        }
      }
    } else {
      // saveDataToLocalStorage(this.props.member.email, "userEmail");

      this.setState({
        currentlyPlaying: null,
        currentTrackId: null,
        currentShow: null,
        isDownloaded: false,
        currentTime: 0
      });

      // saveDataToLocalStorage("", "currentTrackId");
    }

    if (showPlayerTray && isLoggedInAndSameUser) {
      this.setState({ showPlayer: !(showPlayerTray === "false") });
      saveDataToLocalStorage(!(showPlayerTray === "false"), "showPlayerTray");
    } else {
      this.setState({ showPlayer: false });
      saveDataToLocalStorage(false, "showPlayerTray");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("STATE:", this.state);
    // console.log("PROPS:", this.props);
    this.listeners.forEach(l => l(this.state));

    // User logged in
    if (this.props.member !== prevProps.member && this.props.member) {
      // console.log("Yes Member", this.props.member);
      this.reset();
      this.attachListeners();
      // this.restorePersistedState();
    }

    // User logged out
    if (prevProps.member && !this.props.member) {
      // console.log("No Member", this.props.member);
      saveDataToLocalStorage(false, "showPlayerTray");

      this.hidePlayer();
      this.reset();
      // this.restorePersistedState();
    }

    // if (this.state.queue !== prevState.queue) {
    //   this.syncUserState();
    // }

    if (this.state.showPlayer !== prevState.showPlayer) {
      this.syncUserState();
    }

    if (this.state.currentTrackId !== prevState.currentTrackId) {
      this.syncUserState();
    }
  }

  addPlayerListener = cb => {
    this.listeners.push(cb);

    cb(this.state);

    return () => {
      this.listeners.filter(l => l !== cb);
    };
  };

  playNextFromQueue = () => {
    const nextQueue = this.state.queue.filter(
      item => item._id !== this.state.currentTrackId
    );
    if (nextQueue[0]) {
      this.setTrack(nextQueue[0]);
      this.setState({
        queue: nextQueue
      });
      this.play();
    }
  };

  playTrack = async (track, cb) => {
    this.setState({
      currentlyPlaying: track,
      currentTrackId: track._id,
      currentTime: 0,
      showPlayer: true
    });

    saveDataToLocalStorage(track._id, "currentTrackId");
    saveDataToLocalStorage(this.props.member.email, "userEmail");
    saveDataToLocalStorage(true, "showPlayerTray");

    this.setTrack(track);
    this.play();
  };

  setTrack = track => {
    this.audio.src = track.streamUrl;
    this.audio.title = track.title;
  };

  hidePlayer = () => {
    this.setState({ showPlayer: false });

    saveDataToLocalStorage(false, "showPlayerTray");
  };

  togglePlayer = () => {
    /* Pause the audio if user is hiding the player
    if (this.state.showPlayer) {
      this.audio.pause();
    }
    */
    // If a user opens the player but the track queue is empty
    if (!this.state.showPlayer && !this.state.currentTrackId) {
      this.setState({
        currentScreen: "library",
        showPlayer: true
      });

      saveDataToLocalStorage(true, "showPlayerTray");
    } else {
      this.setState({
        showPlayer: !this.state.showPlayer
      });

      saveDataToLocalStorage(!this.state.showPlayer, "showPlayerTray");
    }
  };

  addTrackToQueue = track => {
    this.setState({
      queue: [...this.state.queue, track]
    });
  };

  removeTrackFromQueue = trackId => {
    const nextQueue = this.state.queue.filter(item => item._id !== trackId);
    this.setState({
      queue: nextQueue
    });
  };

  moveUpQueue = track => {
    const trackIndex = this.state.queue.indexOf(track);

    if (trackIndex > 0) {
      const newQueue = this.state.queue;
      newQueue.splice(trackIndex - 1, 0, this.state.queue[trackIndex]);
      newQueue.splice(trackIndex + 1, 1);
      this.setState({
        ...this.state,
        queue: newQueue
      });
    }
  };

  moveDownQueue = track => {
    const trackIndex = this.state.queue.indexOf(track);

    if (
      trackIndex < this.state.queue.length &&
      trackIndex !== this.state.queue.length - 1
    ) {
      const newQueue = this.state.queue;
      newQueue.splice(trackIndex, 0, this.state.queue[trackIndex + 1]);
      newQueue.splice(trackIndex + 2, 1);
      this.setState({
        ...this.state,
        queue: newQueue
      });
    }
  };

  isTrackPresentInQueue = trackId => {
    const track = this.state.queue.find(item => item._id === trackId);
    if (track) {
      return true;
    }

    return false;
  };

  reset = () => {
    this.audio.pause();
    this.userStateRestored = false;
    // this.setState({ showPlayer: false });
  };

  setCurrentShow = show => {
    this.setState({ currentShow: show });
  };

  setCurrentScreen = name => {
    this.setState({ currentScreen: name });
    if (this.currentScreen === null) {
      this.setState({ currentShow: null });
    }
  };

  getCurrentTrack = () => {
    // const track = this.state.queue.find(
    //   track => track._id === this.state.currentTrackId
    // );
    return this.state.currentlyPlaying;
  };

  play = () => {
    this.audio.play();
    if (!this.state.showPlayer) {
      this.setState({ showPlayer: true });

      saveDataToLocalStorage(true, "showPlayerTray");
    }
  };

  changePlaybackSpeed = rate => {
    this.audio.playbackRate = rate;
    this.audio.defaultPlaybackRate = rate;
  };

  seek = time => {
    this.setState({
      currentTime: time
    });
    this.audio.currentTime = time;
  };

  pause = () => {
    this.audio.pause();
  };

  toggle = () => {
    if (this.state.audioStatus === "playing") {
      this.audio.pause();
    } else if (this.state.audioStatus === "paused") {
      this.audio.play();
    }
  };

  setShowPlayerFullScreen = value => {
    this.setState({
      showPlayerFullScreen: value
    });
  };

  toggleShowNotes = () => {
    this.setState({
      showNotes: !this.state.showNotes
    });
  };

  setShowMenu = value => {
    this.setState({
      showMenu: value
    });
  };

  render() {
    const currentTrack = this.getCurrentTrack();

    const queryClient = new QueryClient();
    return (
      <QueryClientProvider client={queryClient}>
        <PlayerContext.Provider
          value={{
            ...this.state,
            audio: this.audio,
            playTrack: this.playTrack,
            play: this.play,
            pause: this.pause,
            reset: this.reset,
            getCurrentTrack: this.getCurrentTrack,
            seek: this.seek,
            isTrackPresentInQueue: this.isTrackPresentInQueue,
            togglePlayer: this.togglePlayer,
            hidePlayer: this.hidePlayer,
            addTrackToQueue: this.addTrackToQueue,
            removeTrackFromQueue: this.removeTrackFromQueue,
            moveUpQueue: this.moveUpQueue,
            moveDownQueue: this.moveDownQueue,
            setCurrentScreen: this.setCurrentScreen,
            setCurrentShow: this.setCurrentShow,
            changePlaybackSpeed: this.changePlaybackSpeed,
            setShowPlayerFullScreen: this.setShowPlayerFullScreen,
            toggleShowNotes: this.toggleShowNotes,
            setShowMenu: this.setShowMenu,
            getSubscriptionState: this.getSubscriptionState
          }}
        >
          {this.state.showPlayer && <BottomPlayer />}
          <PodcastPlayer
            handleClose={() => this.setCurrentScreen(null)}
            currentScreen={this.state.currentScreen}
            currentShow={this.state.currentShow}
            setCurrentShow={this.setCurrentShow}
          />
          {/* <Queue
            handleClose={() => this.setCurrentScreen(null)}
            currentScreen={this.state.currentScreen}
          /> */}
          {currentTrack ? <ShowNotes currentTrack={currentTrack} /> : null}
        </PlayerContext.Provider>
      </QueryClientProvider>
    );
  }
};

function mapStateToProps(state) {
  return {
    member: get(state, ["member"], null),
    userHasSubscription: get(
      state,
      ["isSubscribedUser", "userHasSubscription"],
      false
    )
  };
}

PlayerContextProvider = connect(mapStateToProps, null)(PlayerContextProvider);

export { PlayerContextProvider, PlayerContext };
